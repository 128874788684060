/**
 * Configuration for edi
 * @author Anatoly Deryshev
 */
Ext.namespace('edi.constants');

edi.constants.BEFORE_LOAD_MODULES = edi.constants.BEFORE_LOAD_MODULES || [];
edi.constants.BEFORE_LOAD_MODULES.push((callback) => edi.methods.beforeLoadModules.initRealTimeMessage(callback));
edi.constants.BEFORE_LOAD_MODULES.push((callback) => edi.methods.beforeLoadModules.selectCurrentOrgByIln(callback));
edi.constants.DEFAULT.LOGIN_FORM_WIDTH = 330;
edi.constants.SKIP_QUICK_ANSWER = false;
edi.constants.DEFAULT.BACKGROUND.ON_START = true;
edi.constants.DEFAULT.BACKGROUND.COOKIE_NAME = 'bg-coreplat-nr';
edi.constants.DEFAULT.BACKGROUND.ROTATION = 'MONTH';
edi.constants.DEFAULT.BACKGROUND.CLASS_NAME_PREFIX = 'edi-viewport-background-month-';
edi.constants.DEFAULT.BACKGROUND.IMG_LIST = [
	'winter-forest',
	'sea-cliffs',
	'flower-with-dew',
	'cloud-hill',
	'leaf-with-dew',
	'red-leaf',
	'mountain-slope',
	'spring-leaves',
	'balloons',
	'sakura',
	'hills',
	'forest-glade'
];

Object.assign(edi.constants, {
	DOCUMENT_PROPERTIES: {
		DOCUMENT_DATA_FIELD: 'data',
		DOCUMENT_DATE_FIELD: 'doctime',
		DOCUMENT_NUMBER_FIELD: 'number',
		DOCUMENT_AUTOGEN_FIELD: 'autogen',
		DOCUMENT_PUSH_FIELD: 'push',
		DOCUMENT_AGREEMENT_FIELD: 'agreement',
		DOCUMENT_SUPPLY_AGREEMENT_FIELD: 'supplyAgreement',
		DOCUMENT_FACTORING_AGREEMENT_FIELD: 'factoringAgreement',
		DOCUMENT_FACTORING_CONTEXT_ID: 'factoringContextId',
		CONTAINER_ATTRIBUTES: 'containerAttributes',
		MARK_BEER: 'markBeer',
		MARK_TOBACCO: 'markTobacco',
		DOCUMENT_HEADER_FIELD: 'header',
		DOCUMENT_TOTAL_SUMM: 'totalSumm',
		VERSION_ID_FIELD: 'versionId',
		BP_ID: 'BP_ID',
		BP_NAME: 'bpName',
		DOC_FLOW_TYPE: 'doc_flow_type',
		CUSTOM_FIELDS: 'custom_fields'
	},
	POSITION_ROLE: {
		HEAD: 'HEAD',
		CHIEF_ACCOUNTANT: 'CHIEF_ACCOUNTANT',
		WORKER: 'WORKER'
	},
	ALLOW_INVALID_LINES_CREATION: false,
	ALLOW_EXPORT_DOCUMENTS: true,
	ALLOW_SIMPLE_BP: false,
	ALLOW_SIMPLE_BP_DEV: true,
	STATUSES_PER_PAGE: 50,
	GROUP_REPORT_TYPES: ['ON_DOCUMENTS'],
	USE_BEFORE_SIGN_DOCUMENT_TYPES: [
		'DSF_UNKNOWN',
		'DSF_UNFORMALIZED',
		'DSF_AGREEMENT',
		'DSF_CURRENCY_CONTROL',
		'DSF_EDI_KC2',
		'DSF_EDI_KC3',
		'DSF_EDI_KC11',
		'DSF_EDI_KC14',
		'DSF_INVOICE',
		'DSF_SUPPLEMENTARY_AGREEMENT',
		'DSF_FROV_DFCT_RPT',
		'DSF_FROV_FINE_ACT',
		'DSF_FROV_SCHET',
		'DSF_FROV_SGL_SO_ACT',
		'DSF_FROV_SETOFF_ACT',
		'DSF_PRICAT',
		'EDI_IFTMBC_APPROVAL',
		'DP_CONTAINER',
		'DP_CONTAINER_NE',
		'DP_CONTAINER_DR',
		'PRICAT_EANCOM',
		'DSF_ACT_DIFFRNT',
		'FORMALIZED_DOCUMENT',
		'COMMON_PRICAT',
		'CONTAINER_COMMON_PRICAT',
		'FROV_SIG_SO_ACT2_FROM_X5',
		'DSF_CLIENT'
	],
	SIGN_DOCUMENT_TYPES: [
		'LEGACY_INVOICE',
		'COMMON_PRICAT',
		'LEGACY_DES_ADV',
		'LEGACY_ORDER',
		'LEGACY_ORDER_RESP',
		'LEGACY_DELJIT',
		'DSF_UNKNOWN',
		'DSF_UNFORMALIZED',
		'DSF_AGREEMENT',
		'DSF_EDI_KC2',
		'DSF_EDI_KC3',
		'DSF_EDI_KC11',
		'DSF_EDI_KC14',
		'DSF_INVOICE',
		'DSF_SUPPLEMENTARY_AGREEMENT',
		'DSF_FROV_DFCT_RPT',
		'DSF_FROV_FINE_ACT',
		'DSF_FROV_SCHET',
		'DSF_FROV_SGL_SO_ACT',
		'DSF_FROV_SETOFF_ACT',
		'DSF_ACT_DIFFRNT',
		'DSF_PRICAT',
		'FORMALIZED_DOCUMENT'
	],
	LEGACY_DOCUMENTS: [
		'LEGACY_INVOICE',
		'LEGACY_DES_ADV',
		'LEGACY_ALCDES',
		'LEGACY_ORDER',
		'LEGACY_DELJIT',
		'LEGACY_ORDER_RESP',
		'LEGACY_REC_ADV',
		'LEGACY_RETANN'
	],
	FREE_DOCUMENT_TYPES: [
		'DSF_UNKNOWN',
		'DSF_UNFORMALIZED',
		'DSF_AGREEMENT',
		'DSF_CURRENCY_CONTROL',
		'DSF_EDI_KC2',
		'DSF_EDI_KC3',
		'DSF_EDI_KC11',
		'DSF_EDI_KC14',
		'DSF_INVOICE',
		'DSF_SUPPLEMENTARY_AGREEMENT',
		'DSF_FROV_DFCT_RPT',
		'DSF_FROV_FINE_ACT',
		'DSF_FROV_SCHET',
		'DSF_FROV_SGL_SO_ACT',
		'DSF_FROV_SETOFF_ACT',
		'DSF_RECADV_DIFF_ACT',
		'DSF_ACT_DIFFRNT',
		'DSF_PRICAT',
		'DSF_CLIENT'
	],
	PRICE_CATALOGUE_TYPES: ['PRICE_INCREASE', 'PRODUCTS_ADD', 'PRODUCTS_CHANGE'],
	DOCUMENT_TYPES_BY_NUMBERS: {
		828: 'LEGACY_DES_ADV',
		41: 'LEGACY_REC_ADV',
		36: 'EDI_KC11',
		37: 'EDI_KC14'
	},
	CONTRACT_TYPES: {
		SUPPLY: 'SUPPLY',
		FACTORING: 'FACTORING'
	},
	CONTRACT_SIDES: {
		SUPPLIER: 'SUPPLIER',
		CONSUMER: 'CONSUMER',
		FACTOR: 'FACTOR'
	},
	MAIL_NOTIFICATION_TYPES: [],
	STATE: {
		SELLER_REVIEW: 'SELLER_REVIEW',
		DEBTOR_REVIEW: 'DEBTOR_REVIEW',
		SENDER_REVIEW: 'SENDER_REVIEW',
		SENDER_APPROVAL: 'SENDER_APPROVAL',
		RECEIVER_REVIEW: 'RECEIVER_REVIEW',
		RECEIVER_EDIT: 'RECEIVER_EDIT',
		PARTNER_REVIEW: 'PARTNER_REVIEW',
		REJECTED: 'REJECTED',
		ARCHIVED: 'ARCHIVED',
		SIGNED: 'SIGNED',
		DELETED: 'DELETED',
		WAIT_SENDER_DECISION: 'WAIT_SENDER_DECISION',
		DRAFT: 'DRAFT',
		SENT: 'SENT',
		INVOICE_SENT: 'INVOICE_SENT',
		READ: 'READ',
		SIGNED_BY_CONSUMER: 'SIGNED_BY_CONSUMER',
		UTOCH_SIGNED_BY_CONSUMER: 'UTOCH_SIGNED_BY_CONSUMER',
		WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT: 'WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT',
		UPDATE_RECEIVER_LOA: 'UPDATE_RECEIVER_LOA',
		RECEIVED: 'RECEIVED',
		COMPLETED: 'COMPLETED',
		UTOCH_COMPLETED: 'UTOCH_COMPLETED',
		CONFIRMED: 'CONFIRMED',
		ACTIVE: 'ACTIVE',
		RECEIVER_APPROVAL: 'RECEIVER_APPROVAL',
		DEACTIVATED: 'DEACTIVATED',
		REVIEW: 'REVIEW',
		WAIT_SIGNATURE: 'WAIT_SIGNATURE',
		OPERATOR_INVOICE_SENT_ACK_TO_SENDER_RECEIVE_ADVISE: 'OPERATOR_INVOICE_SENT_ACK_TO_SENDER_RECEIVE_ADVISE',
		OPERATOR_INVOICE_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE: 'OPERATOR_INVOICE_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE',
		RECEIVER_INVOICE_RECEIVED_ACK: 'RECEIVER_INVOICE_RECEIVED_ACK',
		SENDER_INVOICE_RECEIVED_ACK_ADVISE: 'SENDER_INVOICE_RECEIVED_ACK_ADVISE',
		WAITIGN_FOR_CONFIRM_OUTBOX: 'WAITIGN_FOR_CONFIRM_OUTBOX',
		WAITIGN_FOR_CONFIRM_INBOX: 'WAITIGN_FOR_CONFIRM_INBOX',
		WAIT_PARTNER_DECISION: 'WAIT_PARTNER_DECISION',
		WAIT_UTOCH_SIGN: 'WAIT_UTOCH_SIGN',
		ERROR: 'ERROR',
		READY: 'READY',
		CREATED: 'CREATED',
		ACCEPTED: 'ACCEPTED',
		DSF_DP_UTOCH_RECEIVER: 'DSF_DP_UTOCH_RECEIVER',
		DSF_DP_IZVPOL_RECEIVER: 'DSF_DP_IZVPOL_RECEIVER',
		DSF_DP_IZVPOL_SENDER: 'DSF_DP_IZVPOL_SENDER',
		DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL: 'DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL',
		DSF_STATUS_4_DP_IZVPOL: 'DSF_STATUS_4_DP_IZVPOL',
		DP_IZVPOL_TO_ON_AVZ_AWAITING_SIGNATURE: 'DP_IZVPOL_TO_ON_AVZ_AWAITING_SIGNATURE',
		WAIT_RECEIVER_DECISION: 'WAIT_RECEIVER_DECISION',
		PRICAT_EANCOM_DP_IZVPOL_RECEIVER: 'PRICAT_EANCOM_DP_IZVPOL_RECEIVER',
		PRICAT_EANCOM_UTOCH_RECEIVER: 'PRICAT_EANCOM_UTOCH_RECEIVER',
		PRICAT_EANCOM_DP_UTOCH_RECEIVER: 'PRICAT_EANCOM_DP_UTOCH_RECEIVER',
		UTOCH: 'UTOCH',
		PROCESSING_TITLES: 'PROCESSING_TITLES',
		WAIT_SENDER_ACCEPT: 'WAIT_SENDER_ACCEPT',
		SEND_CORRECTION: 'SEND_CORRECTION',
		APPROVED_BY_DRIVER: 'APPROVED_BY_DRIVER',
		SHIPPED: 'SHIPPED',
		ACCEPTED_BY_RECEIVER: 'ACCEPTED_BY_RECEIVER',
		ACCEPTED_BY_DRIVER: 'ACCEPTED_BY_DRIVER',
		DP_IZVPOL_RECEIVER: 'DP_IZVPOL_RECEIVER',
		CORRECTED: 'CORRECTED',
		RENOUNCEMENT: 'RENOUNCEMENT',
		RECEIVED_LEGACY_APERAK: 'RECEIVED_LEGACY_APERAK',
		ANNUL: 'ANNUL',
		ANNUL_UTOCH: 'ANNUL_UTOCH',
		ANNUL_COMPLETED: 'ANNUL_COMPLETED',
		SEND_TO_CRPT: 'SEND_TO_CRPT',
		SEND_TO_CRPT_SUCCESS: 'SEND_TO_CRPT_SUCCESS',
		SEND_TO_CRPT_ERROR: 'SEND_TO_CRPT_ERROR',
		ERROR_PROCESSING: 'ERROR_PROCESSING',
		ERROR_BUS_VALIDATION: 'ERROR_BUS_VALIDATION',
		ERROR_XSD_VALIDATION: 'ERROR_XSD_VALIDATION',
		ERROR_ACTION_WITH_DOC: 'ERROR_ACTION_WITH_DOC',
		ERROR_FS_ALL: 'ERROR_FS_ALL',
		COMMON_PRICAT_DP_IZVPOL_RECEIVER: 'COMMON_PRICAT_DP_IZVPOL_RECEIVER',
		ON_REGISTRATION: 'ON_REGISTRATION',
		WAIT_FNS_RESPONSE: 'WAIT_FNS_RESPONSE',
		FNS_REGISTRATION: 'FNS_REGISTRATION',
		EXPIRED: 'EXPIRED',
		REVOKED: 'REVOKED',
		REGISTERED: 'REGISTERED',
		GET_NUMBER: 'GET_NUMBER',
		CORRECTION: 'CORRECTION',
		AMEND: 'AMEND',
		CANCELED: 'CANCELED',
		ON_ANNUL: 'ON_ANNUL',
		ANNUL_SIGN: 'ANNUL_SIGN',
		ACCEPTED_WITH_COMMENT_13: 'ACCEPTED_WITH_COMMENT_13',
		ACCEPTED_WITH_COMMENT_34: 'ACCEPTED_WITH_COMMENT_34',
		MANUAL_PROCESSING: 'MANUAL_PROCESSING',
		REPEALED: 'REPEALED',
		FOR_SIGNING: 'FOR_SIGNING',
		NOT_DEFINED: 'NOT_DEFINED',
		PROCESSING: 'PROCESSING'
	},
	DIRECTIONS: {
		INCOMING: 'INCOMING',
		INCOMING_FACTOR: 'INCOMING_FACTOR',
		OUTGOING: 'OUTGOING',
		LOOP: 'LOOP',
		UNKNOWN: 'UNKNOWN',
		INBOX: 'INBOX',
		OUTBOX: 'OUTBOX',
		AGGREGATE: 'AGGREGATE',
		BUSINESS_VERIFICATION: 'BUSINESS_VERIFICATION'
	},
	CATALOGS: {
		LOCCAT: 'Localizations catalog',
		DELCAT: 'Delivery points catalog',
		PRODCAT: 'Products catalog',
		LEGACY_PARTIN: 'Partners catalog'
	},
	DOCUMENT_BP_NAMES: {
		DSF_ALL: {
			V1: 'Edidsfv1',
			V2: 'Edidsfv2',
			DSF_UNKNOWN_INTEGRATION: 'DSF_UNKNOWN_INTEGRATION',
			DSF_COMMON_ROAMING_OUT: 'DSF_COMMON_ROAMING_OUT',
			DSF_UNKNOWN_APERAK: 'DSF_UNKNOWN_APERAK',
			DSF_UNKNOWN_CONTRL: 'DSF_UNKNOWN_CONTRL'
		},
		PRICAT_EANCOM_V2: 'PRICAT_EANCOM_V2'
	},
	PACKAGE_TYPES: {
		PALLET: 'BJ',
		OTHER: 'NUL'
	},
	TAX_CALCULATION_TYPES: {
		NET_PRICE: 'netPrice',
		WITHOUT: 'without',
		GROSS_PRICE: 'grossPrice'
	},
	EXPORT_GROUP_REQUEST: {
		STATE: {
			DRAFT: 'DRAFT',
			IN_PROGRESS: 'IN_PROGRESS',
			READY: 'READY',
			COMPLETED: 'COMPLETED',
			REJECTED: 'REJECTED',
			PROCESSED_WITH_ERROR: 'PROCESSED_WITH_ERROR'
		},
		TYPE: {
			SIMPLE: 'SIMPLE',
			COMMON: 'COMMON',
			XML: 'XML',
			ORIGINAL: 'ORIGINAL',
			TRANSIT: 'TRANSIT'
		}
	},
	INTERNAL_LINE_ID: 'ediInternalId',
	MERGE_TYPES: {
		SUBSTITUTE: 'SUBSTITUTE', //Merge matched with initial values and replace whole collection with result of user processing. This type used as default
		MERGE_INTO: 'MERGE_INTO' //Merge matched with initial values and replace only matched
	},
	FIELDS_TO_REMOVE_AFTER_MERGE: ['id', 'header', 'creationDate', 'modifyDate', 'fileId'],
	PUSH_AFTER_SIGN_RULES: {
		DOCUMENT_TYPES: ['FORMALIZED_DOCUMENT'],
		STATES: ['SENT']
	}
});

edi.constants.CUSTOM_FIELDS_TYPES = {
	NULL: 'NULL',
	DATE: 'DATE',
	CHECKBOX: 'CHECKBOX',
	COMBOBOX: 'COMBOBOX'
};

edi.constants.CUSTOM_FIELDS_DATES_FORMATS = ['Y-m-d', 'd.m.Y', 'Y.m.d'];

edi.constants.DR_ATTACHMENTS = [
	'DP_PRILFORMZVTDR',
	'DP_PRILSHSVERDR',
	'DP_PRILOSTRBDR',
	'DP_PRILCOMMUSLDR',
	'DP_PRILUVIZMCENDR',
	'DP_PRILSANKDR',
	'DP_PRILFAOPTUZNE',
	'DP_PRILPROTSOGLNE',
	'DP_PRILANTIKORDR',
	'DP_PRILFAOPTUZDR',
	'DP_PRILOSTRBNE',
	'DP_PRILKTOVDR',
	'DP_PRILANTIKORNE',
	'DP_PRILPROTSOGLDR',
	'DP_PRILKPOSTDR',
	'DP_PRILKTOVNE',
	'DP_PRILSHSVERNE',
	'DP_PRILKPOSTNE',
	'DP_PRILUVIZMCENNE',
	'DP_PRILCOMMUSLNE',
	'DP_PRILORGEDOCDR',
	'DP_PRILFORMZVTNE'
];

edi.constants.FREE_DOCUMENT_TYPES.push('ON_AVZ');

Object.assign(edi.constants.DOCUMENT_ACTIONS, {
	ARCHIVE_RESTORE: 'ARCHIVE_RESTORE',
	DETAILS: 'DETAILS',
	SIGN: 'SIGN',
	REJECT: 'REJECT',
	REPROCESS: 'REPROCESS',
	SEND: 'SEND',
	READ: 'READ',
	READ_AND_COMPLETE: 'READ_AND_COMPLETE',
	COMPLETE: 'COMPLETE',
	CONVERT_TO_DRAFT: 'CONVERT_TO_DRAFT',
	COPY: 'COPY',
	ROUTE_CONFIRM: 'ROUTE_CONFIRM',
	ROUTE_REJECT: 'ROUTE_REJECT',
	EXPORT: 'EXPORT',
	EXPORT_DOCUMENTS: 'EXPORT_DOCUMENTS',
	EXPORT_DATASOURCE: 'EXPORT_DATASOURCE',
	EXPORT_GROUP: 'EXPORT_GROUP',
	EXPORT_GROUP_XML: 'EXPORT_GROUP_XML',
	PRINT: 'PRINT',
	RESTORE: 'RESTORE',
	ACTIVATE: 'ACTIVATE',
	DEACTIVATE: 'DEACTIVATE',
	SEND_TO_APPROVE: 'SEND_TO_APPROVE',
	APPROVE: 'APPROVE',
	CLOSE: 'CLOSE',
	REFRESH: 'REFRESH',
	CUSTOM_BUTTONS: 'CUSTOM_BUTTONS',
	PACKAGING: 'PACKAGING',
	GROUP_PRINT: 'GROUP_PRINT',
	CLARIFY: 'CLARIFY',
	RENOUNCEMENT: 'RENOUNCEMENT',
	ANNUL: 'ANNUL',
	QUICK_ANSWER_REJECT: 'QUICK_ANSWER_REJECT',
	QUICK_ANSWER_CONFIRM: 'QUICK_ANSWER_CONFIRM',
	REVOKE: 'REVOKE'
});

edi.constants.ADDITIONAL_ACTIONS_ICONS_HANDLERS.push(function (action) {
	var icon = '';
	if (edi.constants.DOCUMENT_ACTIONS.SIGN === action) {
		icon = edi.constants.ICONS.SIGN_NEW; //OVERRIDE Ediweb
	} else if (
		edi.constants.DOCUMENT_ACTIONS.EXPORT === action ||
		edi.constants.DOCUMENT_ACTIONS.EXPORT_GROUP === action
	) {
		icon = edi.constants.ICONS.FILE_DOWNLOAD;
	} else if (edi.constants.DOCUMENT_ACTIONS.EXPORT_DOCUMENTS === action) {
		icon = edi.constants.ICONS.CLOUD_DOWNLOAD;
	} else if (
		edi.constants.DOCUMENT_ACTIONS.PRINT === action ||
		edi.constants.DOCUMENT_ACTIONS.GROUP_PRINT === action
	) {
		icon = edi.constants.ICONS.PRINT;
	} else if (
		edi.constants.DOCUMENT_ACTIONS.REJECT === action ||
		edi.constants.DOCUMENT_ACTIONS.ROUTE_REJECT === action ||
		edi.constants.DOCUMENT_ACTIONS.DEACTIVATE === action
	) {
		icon = edi.constants.ICONS.REJECT;
	} else if (edi.constants.DOCUMENT_ACTIONS.ANNUL === action) {
		icon = edi.constants.ICONS.MULTI_MODE_OFF;
	} else if (edi.constants.DOCUMENT_ACTIONS.CLARIFY === action) {
		icon = edi.constants.ICONS.ANNOUNCEMENT;
	} else if (edi.constants.DOCUMENT_ACTIONS.RENOUNCEMENT === action) {
		icon = edi.constants.ICONS.REMOVE_OUTLINE;
	} else if (edi.constants.DOCUMENT_ACTIONS.CONVERT_TO_DRAFT === action) {
		icon = edi.constants.ICONS.DRAFT;
	} else if (edi.constants.DOCUMENT_ACTIONS.SEND === action) {
		icon = edi.constants.ICONS.SEND;
	} else if (edi.constants.DOCUMENT_ACTIONS.COPY === action) {
		icon = edi.constants.ICONS.COPY;
	} else if (
		edi.constants.DOCUMENT_ACTIONS.COMPLETE === action ||
		edi.constants.DOCUMENT_ACTIONS.ROUTE_CONFIRM === action ||
		edi.constants.DOCUMENT_ACTIONS.ACTIVATE === action ||
		edi.constants.DOCUMENT_ACTIONS.READ_AND_COMPLETE === action
	) {
		icon = edi.constants.ICONS.DONE;
	} else if (edi.constants.DOCUMENT_ACTIONS.RESTORE === action) {
		icon = edi.constants.ICONS.REPLY;
	} else if (edi.constants.DOCUMENT_ACTIONS.ARCHIVE_RESTORE === action) {
		icon = edi.constants.ICONS.RESTORE;
	} else if (edi.constants.DOCUMENT_ACTIONS.APPROVE === action) {
		icon = edi.constants.ICONS.APPROVE;
	} else if (edi.constants.DOCUMENT_ACTIONS.SEND_TO_APPROVE === action) {
		icon = edi.constants.ICONS.SEND_APPROVE;
	} else if (edi.constants.DOCUMENT_ACTIONS.ADD === action) {
		icon = edi.constants.ICONS.ADD;
	} else if (edi.constants.DOCUMENT_ACTIONS.SAVE === action) {
		icon = edi.constants.ICONS.SAVE;
	} else if (edi.constants.DOCUMENT_ACTIONS.QUICK_ANSWER_CONFIRM === action) {
		icon = edi.constants.ICONS.CHECK_CIRCLE;
	} else if (edi.constants.DOCUMENT_ACTIONS.QUICK_ANSWER_REJECT === action) {
		icon = edi.constants.ICONS.CANCEL;
	} else if (edi.constants.DOCUMENT_ACTIONS.REVOKE === action) {
		icon = edi.constants.ICONS.CANCEL;
	} else if (edi.constants.DOCUMENT_ACTIONS.REPROCESS === action) {
		icon = edi.constants.ICONS.REFRESH;
	} else if (edi.constants.DOCUMENT_ACTIONS.REPROCESS_MANUALLY === action) {
		icon = edi.constants.ICONS.SYNC_PROBLEM;
	}
	return icon;
});
Object.assign(edi.constants.DOCUMENT_ACTIONS_PERMISSIONS_CHECK_MAP, {
	DETAILS: 'READ',
	SIGN: 'SIGN',
	REJECT: 'REJECT',
	SEND: 'SEND',
	REPROCESS: 'REPROCESS',
	COMPLETE: 'COMPLETE',
	CONVERT_TO_DRAFT: 'CREATE',
	COPY: 'CREATE',
	SEND_TO_APPROVE: 'SEND_TO_APPROVE',
	APPROVE: 'APPROVE',
	CLARIFY: 'CLARIFY',
	ANNUL: 'ANNUL'
});
Object.assign(edi.constants.DOCUMENT_TYPES, {
	UNKNOWN: 'UNKNOWN',
	LEGACY_INVOICE: 'LEGACY_INVOICE',
	LEGACY_DES_ADV: 'LEGACY_DES_ADV',
	LEGACY_ALCDES: 'LEGACY_ALCDES',
	LEGACY_ORDER: 'LEGACY_ORDER',
	LEGACY_ORDER_RESP: 'LEGACY_ORDER_RESP',
	LEGACY_REC_ADV: 'LEGACY_REC_ADV',
	LEGACY_RETANN: 'LEGACY_RETANN',
	DSF_UNKNOWN: 'DSF_UNKNOWN',
	DSF_UNFORMALIZED: 'DSF_UNFORMALIZED',
	DSF_AGREEMENT: 'DSF_AGREEMENT',
	DSF_CURRENCY_CONTROL: 'DSF_CURRENCY_CONTROL',
	DSF_EDI_KC2: 'DSF_EDI_KC2',
	DSF_EDI_KC3: 'DSF_EDI_KC3',
	DSF_EDI_KC11: 'DSF_EDI_KC11',
	DSF_EDI_KC14: 'DSF_EDI_KC14',
	DSF_SUPPLEMENTARY_AGREEMENT: 'DSF_SUPPLEMENTARY_AGREEMENT',
	DSF_FROV_DFCT_RPT: 'DSF_FROV_DFCT_RPT',
	DSF_FROV_FINE_ACT: 'DSF_FROV_FINE_ACT',
	DSF_FROV_SCHET: 'DSF_FROV_SCHET',
	DSF_FROV_SGL_SO_ACT: 'DSF_FROV_SGL_SO_ACT',
	DSF_FROV_SETOFF_ACT: 'DSF_FROV_SETOFF_ACT',
	DSF_INVOICE: 'DSF_INVOICE',
	DSF_PRICAT: 'DSF_PRICAT',
	DSF_REESTR_ROUTE: 'DSF_REESTR_ROUTE',
	LEGACY_PRODCAT: 'LEGACY_PRODCAT_V2',
	LEGACY_DELCAT: 'LEGACY_DELCAT_V2',
	LEGACY_LOCCAT: 'LEGACY_LOCCAT_V2',
	LEGACY_PRICAT: 'LEGACY_PRICAT',
	LEGACY_APERAK: 'LEGACY_APERAK',
	LEGACY_DELJIT: 'LEGACY_DELJIT',
	EDI_IFTMBF: 'EDI_IFTMBF',
	EDI_IFTMBC: 'EDI_IFTMBC',
	EDI_IFTMBC_APPROVAL: 'EDI_IFTMBC_APPROVAL',
	EDI_IFTMBF_APPROVAL: 'EDI_IFTMBF_APPROVAL',
	SLSRPT: 'SLSRPT',
	SLSRPT_LINE: 'SLSRPT_LINE',
	INVRPT: 'INVRPT',
	INVRPT_LINE: 'INVRPT_LINE',
	ON_AVZ_PRANNUL_UTOCH: 'ON_AVZ_PRANNUL_UTOCH',
	ON_AVZ_PRANNUL: 'ON_AVZ_PRANNUL',
	ON_AVZ_PRANNUL_PDPOL: 'ON_AVZ_PRANNUL_PDPOL',
	DP_PDPOL_AVZ: 'DP_PDPOL_AVZ',
	EDI_DSF_DP_PRANNUL: 'EDI_DSF_DP_PRANNUL',
	EDI_DSF_DP_PRANNUL_UTOCH: 'EDI_DSF_DP_PRANNUL_UTOCH',
	EDI_DSF_DP_UVUTOCH: 'EDI_DSF_DP_UVUTOCH',
	EDI_PRICAT_EANCOM_DP_UVUTOCH: 'EDI_PRICAT_EANCOM_DP_UVUTOCH',
	EDI_DSF_DP_IZVPOL: 'EDI_DSF_DP_IZVPOL',
	DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL: 'DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL',
	DSF_UNFORMALIZED_STATUS_5_DP_UVUTOCH: 'DSF_UNFORMALIZED_STATUS_5_DP_UVUTOCH',
	DP_IZVPOL_AVZ: 'DP_IZVPOL_AVZ',
	EDI_PRICAT_EANCOM_DP_IZVPOL: 'EDI_PRICAT_EANCOM_DP_IZVPOL',
	DP_CONTAINER_NE: 'DP_CONTAINER_NE',
	ON_SERVICE_CONTAINER: 'ON_SERVICE_CONTAINER',
	ON_SERVICE_ATTACHMENT: 'ON_SERVICE_ATTACHMENT',
	DP_CONTAINER: 'DP_CONTAINER',
	DP_CONTAINER_DR: 'DP_CONTAINER_DR',
	DP_CONTAINER_IZVPOL: 'DP_CONTAINER_IZVPOL',
	DP_CONTAINER_UVUTOCH: 'DP_CONTAINER_UVUTOCH',
	DP_CONTAINER_PRANNUL: 'DP_CONTAINER_PRANNUL',
	DP_USTDOK_ADDITIONAL: 'DP_USTDOK_ADDITIONAL',
	DP_USTDOKADDITIONAL: 'DP_USTDOKADDITIONAL',
	PRICAT_EANCOM: 'PRICAT_EANCOM',
	X5_AGREEMENT_CONTAINER: 'X5_AGREEMENT_CONTAINER',
	X5_CONTRACT_NOTIFICATION: 'X5_CONTRACT_NOTIFICATION',
	SUPPLIER_AGREEMENT: 'SUPPLIER_AGREEMENT',
	LOA_RECEIVER: 'LOA_RECEIVER',
	LOA_SENDER: 'LOA_SENDER',
	EDI_CONTRL: 'EDI_CONTRL',
	DOC_ARCHIVE: 'DOC_ARCHIVE',
	DSF_RECADV_DIFF_ACT: 'DSF_RECADV_DIFF_ACT',
	DSF_ACT_DIFFRNT: 'DSF_ACT_DIFFRNT',
	DELFOR: 'DELFOR',
	EDI_SLSFCT: 'EDI_SLSFCT',
	EDI_SLSRPT: 'EDI_SLSRPT',
	LOGSET: 'LOGSET',
	EDI_INVRPT: 'EDI_INVRPT',
	COMMON_PRICAT: 'COMMON_PRICAT',
	CONTAINER_COMMON_PRICAT: 'CONTAINER_COMMON_PRICAT',
	PRICAT_APPROVAL: 'PRICAT_APPROVAL',
	PRICAT_SIGN_APPROVAL: 'PRICAT_SIGN_APPROVAL',
	PRICAT_SIMPLE: 'PRICAT_SIMPLE',
	PRICAT_WITH_SIGN: 'PRICAT_WITH_SIGN',
	FORMALIZED_DOCUMENT: 'FORMALIZED_DOCUMENT',
	DSF_ONBOARDING_CP: 'DSF_ONBOARDING_CP',
	DSF_ONBOARDING_FP: 'DSF_ONBOARDING_FP',
	DSF_ONBOARDING_FP02: 'DSF_ONBOARDING_FP02',
	DSF_ONBOARDING_FP12: 'DSF_ONBOARDING_FP12',
	DSF_ONBOARDING_FP14: 'DSF_ONBOARDING_FP14',
	ATTACHMENT_PROD_CATALOG: 'ATTACHMENT_PROD_CATALOG',
	DP_PRILPROTSOGL_ITEMS: 'DP_PRILPROTSOGL_ITEMS',
	ATTACHMENT_PROD_CATALOGNE: 'ATTACHMENT_PROD_CATALOGNE',
	DP_PRILPROTSOGLNE_ITEMS: 'DP_PRILPROTSOGLNE_ITEMS',
	ATTACHMENT_PROD_CATALOGDR: 'ATTACHMENT_PROD_CATALOGDR',
	DP_PRILPROTSOGLDR_ITEMS: 'DP_PRILPROTSOGLDR_ITEMS',
	DP_DOGPOST_P1: 'DP_DOGPOST_P1',
	DP_DOGPOSTDR_P1: 'DP_DOGPOSTDR_P1',
	DP_PRILPROTSOGLDR_P1: 'DP_PRILPROTSOGLDR_P1',
	DP_PRILORGEDOCDR_P1: 'DP_PRILORGEDOCDR_P1',
	DP_PRILOSTRBDR_P1: 'DP_PRILOSTRBDR_P1',
	DP_PRILCOMMUSLDR_P1: 'DP_PRILCOMMUSLDR_P1',
	DP_PRILSANKDR_P1: 'DP_PRILSANKDR_P1',
	DP_PRILKPOSTDR_P1: 'DP_PRILKPOSTDR_P1',
	DP_PRILKTOVDR_P1: 'DP_PRILKTOVDR_P1',
	DP_PRILANTIKORDR_P1: 'DP_PRILANTIKORDR_P1',
	DP_PRILANTIKORDR: 'DP_PRILANTIKORDR',
	DP_PRILUVIZMCENDR: 'DP_PRILUVIZMCENDR',
	DP_PRILSHSVERDR: 'DP_PRILSHSVERDR',
	DP_PRILSANKDR: 'DP_PRILSANKDR',
	DP_PRILFORMZVTDR: 'DP_PRILFORMZVTDR',
	DP_PRILFAOPTUZDR: 'DP_PRILFAOPTUZDR',
	DP_PRILORGEDOCDR: 'DP_PRILORGEDOCDR',
	DP_DOGPOSTDR: 'DP_DOGPOSTDR',
	DP_PRILPROTSOGLDR: 'DP_PRILPROTSOGLDR',
	DP_PRILOSTRBDR: 'DP_PRILOSTRBDR',
	DP_PRILCOMMUSLDR: 'DP_PRILCOMMUSLDR',
	DP_PRILKPOSTDR: 'DP_PRILKPOSTDR',
	DP_PRILKTOVDR: 'DP_PRILKTOVDR',
	DP_PRILUVIZMCENNE: 'DP_PRILUVIZMCENNE',
	DP_PRILSHSVERNE: 'DP_PRILSHSVERNE',
	DP_PRILFORMZVTNE: 'DP_PRILFORMZVTNE',
	DP_PRILFAOPTUZNE: 'DP_PRILFAOPTUZNE',
	DP_DOGPOSTNE: 'DP_DOGPOSTNE',
	DP_DOGPOSTNE_P1: 'DP_DOGPOSTNE_P1',
	DP_PRILPROTSOGLNE: 'DP_PRILPROTSOGLNE',
	DP_PRILORGEDOCNE: 'DP_PRILORGEDOCNE',
	DP_PRILOSTRBNE: 'DP_PRILOSTRBNE',
	DP_PRILCOMMUSLNE: 'DP_PRILCOMMUSLNE',
	DP_PRILSANKNE: 'DP_PRILSANKNE',
	DP_PRILKPOSTNE: 'DP_PRILKPOSTNE',
	DP_PRILKTOVNE: 'DP_PRILKTOVNE',
	DP_PRILANTIKORNE: 'DP_PRILANTIKORNE',
	DP_PRILCOMMUSLDR_ATTACHMENT: 'DP_PRILCOMMUSLDR_ATTACHMENT',
	DP_PRILCOMMUSLNE_ATTACHMENT: 'DP_PRILCOMMUSLNE_ATTACHMENT',
	ON_AVZ: 'ON_AVZ',
	ON_AVZ_ATTACHMENT: 'ON_AVZ_ATTACHMENT',
	DP_PRAZN: 'DP_PRAZN',
	DSF_UNKNOWN_APERAK: 'DSF_UNKNOWN_APERAK',
	LEGACY_PARTIN: 'LEGACY_PARTIN',
	FROV_SIG_SO_ACT2_FROM_X5: 'FROV_SIG_SO_ACT2_FROM_X5',
	COMMON_PRICAT_DP_IZVPOL: 'COMMON_PRICAT_DP_IZVPOL',
	DSF_CLIENT: 'DSF_CLIENT',
	OVZ_FROM_X5: 'OVZ_FROM_X5',
	ROSEU_INVITATION: 'ROSEU_INVITATION',
	POWER_OF_ATTORNEY: 'POWER_OF_ATTORNEY',
	LEGACY_ORDER_SEPARATE: 'LEGACY_ORDER_SEPARATE',
	LEGACY_ORDER_RESP_SEPARATE: 'LEGACY_ORDER_RESP_SEPARATE',
	LEGACY_DES_ADV_SEPARATE: 'LEGACY_DES_ADV_SEPARATE',
	LEGACY_REC_ADV_SEPARATE: 'LEGACY_REC_ADV_SEPARATE',
	LEGACY_INVOICE_SEPARATE: 'LEGACY_INVOICE_SEPARATE',
	PACKING_LIST: 'PACKING_LIST',
	DOCUMENT_PACKAGE: 'PACKAGE_OBJECT'
});

//аннулирование ДСФ
edi.constants.EXCLUDE_ANNUL_DOCUMENT_TYPES = [edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT];

edi.constants.OUTGOING_ANNUL_DOCUMENT_TYPES = edi.constants.FREE_DOCUMENT_TYPES.filter((docType) => {
	return edi.constants.EXCLUDE_ANNUL_DOCUMENT_TYPES.indexOf(docType) === -1;
});

edi.constants.OUTGOING_ANNUL_DOCUMENT_TYPES.push(edi.constants.DOCUMENT_TYPES.ON_AVZ);
edi.constants.INCOMING_ANNUL_DOCUMENT_TYPES = edi.constants.OUTGOING_ANNUL_DOCUMENT_TYPES;

edi.constants.RECEIPTS = [
	edi.constants.DOCUMENT_TYPES.DP_IZVPOL_AVZ,
	edi.constants.DOCUMENT_TYPES.DP_PDPOL_AVZ,
	edi.constants.DOCUMENT_TYPES.ON_AVZ_PRANNUL,
	edi.constants.DOCUMENT_TYPES.ON_AVZ_PRANNUL_PDPOL,
	edi.constants.DOCUMENT_TYPES.ON_AVZ_PRANNUL_UTOCH
];

edi.constants.documentsForVisibleTemplates = [
	edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
	edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
	edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
	edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_PRICAT
];

edi.constants.APPERAK_DOCUMENT_TYPES_MAP = {
	ORDRSP: edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
	DESADV: edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
	INVOICE: edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
	EDI_IFTMBC_APPROVAL: edi.constants.DOCUMENT_TYPES.EDI_IFTMBC_APPROVAL,
	DSF_FROV_DFCT_RPT: 'UTOCH_FROV_DR',
	DSF_FROV_FINE_ACT: 'UTOCH_FROV_FA',
	LOGSET: edi.constants.DOCUMENT_TYPES.LOGSET,
	RETANN: edi.constants.DOCUMENT_TYPES.LEGACY_RETANN
};

edi.constants.DSF_TYPES_FROV = [
	edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT
];

edi.constants.PRODUCT_TOTALS = {
	USE_DOCDATA: {
		set_rows: [
			edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
			edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
			edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
			edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
			edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV
		],
		load_rows: [
			edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES,
			edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT,
			edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
			edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
			edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
			edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
			edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV
		]
	}
};

edi.constants.UPD_UKD_DOCUMENT_TYPES = [];
edi.constants.ACTION_ON_DOCUMENTS_ADDED_DOCUMENT_TYPES = [];

edi.constants.USE_BEFORE_SIGN_RULES = [
	{
		DOCUMENT_TYPES: [
			edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
			edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
			edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
			edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
			edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
			edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
			edi.constants.DOCUMENT_TYPES.DSF_PRICAT,
			edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
			edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL,
			edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
			edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT
		],
		STATES: [edi.constants.STATE.SENT, edi.constants.STATE.WAIT_RECEIVER_DECISION]
	},
	{
		DOCUMENT_TYPES: [edi.constants.DOCUMENT_TYPES.COMMON_PRICAT],
		STATES: [edi.constants.STATE.RECEIVER_APPROVAL]
	},
	{
		DOCUMENT_TYPES: [edi.constants.DOCUMENT_TYPES.FROV_SIG_SO_ACT2_FROM_X5],
		STATES: [edi.constants.STATE.SENT]
	}
];

edi.constants.PUSH_TO_READ_BEFORE_SIGN_RULES = {
	DOCUMENT_TYPES: [
		edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
		edi.constants.DOCUMENT_TYPES.LOGSET,
		edi.constants.DOCUMENT_TYPES.DELFOR
	],
	STATES: [edi.constants.STATE.SENT]
};

edi.constants.CATALOG_TYPES = {};
edi.constants.CATALOG_TYPES[edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT] = 'prodcat';
edi.constants.CATALOG_TYPES[edi.constants.DOCUMENT_TYPES.LEGACY_LOCCAT] = 'loccat';
edi.constants.CATALOG_TYPES[edi.constants.DOCUMENT_TYPES.LEGACY_DELCAT] = 'delcat';
edi.constants.CATALOG_TYPES[edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN] = 'partin';

edi.constants.CATALOG_TYPES_UPLOAD = [
	edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT,
	edi.constants.DOCUMENT_TYPES.LEGACY_DELCAT,
	edi.constants.DOCUMENT_TYPES.LEGACY_LOCCAT,
	edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN
];

edi.constants.CATALOG_TYPES_UPLOAD_WITHOUT_CONFIRMATION = [edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN];

edi.constants.CATALOG_STATES = {};
edi.constants.CATALOG_STATES.ACTIVE = 'ACTIVE';
edi.constants.CATALOG_STATES.LOADING = 'LOADING';
edi.constants.CATALOG_STATES.RELOADING = 'RELOADING';
edi.constants.CATALOG_STATES.DRAFT = 'DRAFT';
edi.constants.CATALOG_STATES.DELETED = 'DELETED';
edi.constants.CATALOG_STATES.ERROR = 'ERROR';
edi.constants.CATALOG_STATES.COMPLETED = 'COMPLETED';
edi.constants.STATE.REJECTED_BY_RECEIVER = 'REJECTED_BY_RECEIVER';

edi.constants.CATALOGS = [
	edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT,
	edi.constants.DOCUMENT_TYPES.LEGACY_DELCAT,
	edi.constants.DOCUMENT_TYPES.LEGACY_LOCCAT,
	edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN
];

edi.constants.DOCUMENT_PACKAGES_INCLUDES_DOC_TYPES = [
	'DP_DOGPOST',
	'DP_PRIL01ORGEDOC',
	'DP_PRIL02OSTRB',
	'DP_PRILUVIZMCEN',
	'DP_PRIL03COMMUSL',
	'DP_PRIL04SANK',
	'DP_PRIL05KPOST',
	'DP_PRIL06KTOV',
	'DP_PRIL07SHSVER',
	'DP_PRIL08FORMZVT',
	'DP_PRIL09FAOPTUZ',
	'DP_PRIL10TOSGPT',
	'DP_PRIL11ANTIKOR',
	'DP_PRILPROTSOGL'
];
edi.constants.DOCUMENT_PACKAGES_DR_INCLUDES_DOC_TYPES = [
	'DP_DOGPOSTDR',
	'DP_PRILPROTSOGLDR',
	'DP_PRILORGEDOCDR',
	'DP_PRILUVIZMCENDR',
	'DP_PRILOSTRBDR',
	'DP_PRILCOMMUSLDR',
	'DP_PRILSANKDR',
	'DP_PRILKPOSTDR',
	'DP_PRILKTOVDR',
	'DP_PRILSHSVERDR',
	'DP_PRILFORMZVTDR',
	'DP_PRILFAOPTUZDR',
	'DP_PRILANTIKORDR'
];
edi.constants.DOCUMENT_PACKAGES_NE_INCLUDES_DOC_TYPES = [
	'DP_DOGPOSTNE',
	'DP_PRILPROTSOGLNE',
	'DP_PRILORGEDOCNE',
	'DP_PRILUVIZMCENNE',
	'DP_PRILOSTRBNE',
	'DP_PRILCOMMUSLNE',
	'DP_PRILSANKNE',
	'DP_PRILKPOSTNE',
	'DP_PRILKTOVNE',
	'DP_PRILSHSVERNE',
	'DP_PRILFORMZVTNE',
	'DP_PRILFAOPTUZNE',
	'DP_PRILANTIKORNE'
];
edi.constants.DOCUMENT_PACKAGES_STATUTORY_DOC_TYPES = {
	LEGAL_ENTITY: [
		'DP_USTDOKUSTAV',
		'DP_USTDOKVEGRUL',
		'DP_USTDOKSGRUL',
		'DP_USTDOKSPNULNO',
		'DP_USTDOKRNDGB',
		edi.constants.DOCUMENT_TYPES.DP_PRAZN,
		'DP_USTDOKADDITIONAL'
	],
	INDIVIDUAL: ['DP_USTDOKSGRFL', 'DP_USTDOKSVINN', edi.constants.DOCUMENT_TYPES.DP_PRAZN, 'DP_USTDOKADDITIONAL'],
	NE: ['DP_PRILUVIZMCENNE', 'DP_PRILSHSVERNE', 'DP_PRILFORMZVTNE', 'DP_PRILFAOPTUZNE'],
	DR: ['DP_PRILFAOPTUZDR', 'DP_PRILSHSVERDR', 'DP_PRILFORMZVTDR', 'DP_PRILUVIZMCENDR']
};

edi.constants.REPORT_REQUEST = {
	STATE: {
		DRAFT: 'DRAFT',
		WAIT_TO_CREATE_DOCUMENT: 'WAIT_TO_CREATE_DOCUMENT',
		CHECK_REPORT_REQUEST: 'CHECK_REPORT_REQUEST',
		WAITING_FOR_DECISION: 'WAITING_FOR_DECISION',
		COMPLETED: 'COMPLETED',
		PROCESSED_WITH_ERROR: 'PROCESSED_WITH_ERROR'
	},
	TYPE: ['DOCUMENT_REPORT_LIST']
};
Object.assign(edi.constants.BUSINESS_PROCESS_PROPERTIES, {
	SIMPLE_FNS: 'SIMPLE_FNS',
	TASK_ID: 'TASK_ID',
	SIGNATURE: 'SIGNATURE',
	REVOKE: 'REVOC'
});

edi.constants.MODULE_NAME.EDI = 'EDI';
edi.constants.NAVIGATION_NAME = 'edi';

edi.constants.CERTIFICATE.ACTIVE_STATE = edi.constants.STATE.ACTIVE;
edi.constants.CREATE_MODULE_NAME_BY_TYPE = {};
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LEGACY_ORDER] = 'document.create.order';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP] = 'document.create.ordrsp';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT] = 'document.create.deljit';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV] = 'document.create.recadv';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LEGACY_RETANN] = 'document.create.retann';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE] = 'document.create.invoice';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV] = 'document.create.desadv';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES] = 'document.create.alcdes';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_EDI_KC2] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_EDI_KC3] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_EDI_KC11] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_EDI_KC14] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_INVOICE] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_PRICAT] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.COMMON_PRICAT] = 'document.create.commonpricat';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT] =
	'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_CLIENT] = 'document.create.dsf_client';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT] =
	'document.create.formalized';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_IFTMBF] = 'document.create.iftmbf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_IFTMBC] = 'document.create.iftmbc';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DELFOR] = 'document.create.delfor';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM] = 'document.create.pricateancom';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM + '_1'] =
	'document.create.pricateancom';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM + '_2'] =
	'document.create.pricateancom_v2';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_IFTMBC_APPROVAL] =
	'document.create.iftmbc_approval';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_IFTMBF_APPROVAL] =
	'document.create.iftmbf_approval';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DP_CONTAINER] = 'document.create.package';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DP_CONTAINER_NE] = 'document.create.package.ne';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.ON_SERVICE_CONTAINER] =
	'document.create.on.service.container';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY] = 'power.of.attorney.create';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY + '_002'] =
	'power.of.attorney.create';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY + '_EMCHD_1'] =
	'power.of.attorney.create_003';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DOCUMENT_PACKAGE] = 'create.documentPackage';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE = {};
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LEGACY_ORDER] = 'document.details.order';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP] = 'document.details.ordrsp';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT] = 'document.details.deljit';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV] = 'document.details.recadv';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LEGACY_RETANN] = 'document.details.retann';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.X5_AGREEMENT_CONTAINER] =
	'document.details.x5.agreement.container';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE] = 'document.details.invoice';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV] = 'document.details.desadv';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES] = 'document.details.alcdes';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_EDI_KC2] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_EDI_KC3] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_EDI_KC11] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_EDI_KC14] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_INVOICE] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_PRICAT] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.COMMON_PRICAT] = 'document.details.commonpricat';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT] =
	'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_RECADV_DIFF_ACT] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_REESTR_ROUTE] =
	'document.details.dsf_reestr_route';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_CLIENT] = 'document.details.dsf_client';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT] =
	'document.details.formalized';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_IFTMBF] = 'document.details.iftmbf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_IFTMBC] = 'document.details.iftmbc';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DELFOR] = 'document.details.delfor';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_SLSRPT] = 'document.details.slsrpt';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_IFTMBC_APPROVAL] =
	'document.details.iftmbc_approval';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_IFTMBF_APPROVAL] =
	'document.details.iftmbf_approval';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM] = 'document.details.pricateancom';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM + '_1'] =
	'document.details.pricateancom';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM + '_2'] =
	'document.details.pricateancom_v2';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DP_CONTAINER] = 'document.details.package';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DP_CONTAINER_NE] = 'document.details.package.ne';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DP_CONTAINER_DR] = 'document.details.package.dr';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.ON_SERVICE_CONTAINER] =
	'document.details.on.service.container';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_SLSFCT] = 'document.details.slsfct';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LOGSET] = 'document.details.logset';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_INVRPT] = 'document.details.invrpt';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.ON_AVZ] = 'document.details.on_avz';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.CONTAINER_COMMON_PRICAT] =
	'document.details.container_common_pricat';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN] = 'partin.details';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.FROV_SIG_SO_ACT2_FROM_X5] =
	'document.details.frov_sig_so_act2_from_x5';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.OVZ_FROM_X5] = 'document.details.ovz_from_x5';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY] = 'power.of.attorney.details';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY + '_002'] =
	'power.of.attorney.details';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY + '_EMCHD_1'] =
	'power.of.attorney.details_003';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.PACKING_LIST] = 'packing.list';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DOCUMENT_PACKAGE] = 'details.documentPackage';

edi.constants.APERAK_CODES = {
	CODE_6: 6,
	CODE_10: 10,
	CODE_13: 13,
	CODE_23: 23,
	CODE_27: 27,
	CODE_29: 29,
	CODE_34: 34
};

edi.constants.APERAK_DOCUMENT_TYPES = {
	INVOICE: 'INVOICE',
	DESADV: 'DESADV'
};

Ext.merge(edi.constants, {
	MODULES: {
		DOCUMENTS: {
			COPY_USING_UI: [
				edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
				edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
				edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
				edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES,
				edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
				edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
				edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
				edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
				edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
				edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL,
				edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
				edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
				edi.constants.DOCUMENT_TYPES.DSF_PRICAT,
				edi.constants.DOCUMENT_TYPES.DSF_CLIENT,
				edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT,
				edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
				edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
				edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY
			],
			INCLUDED_TYPES: [
				edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
				edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
				edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
				edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
				edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT,
				edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
				edi.constants.DOCUMENT_TYPES.LEGACY_RETANN,
				edi.constants.DOCUMENT_TYPES.PACKING_LIST,
				edi.constants.DOCUMENT_TYPES.EDI_IFTMBF,
				edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
				edi.constants.DOCUMENT_TYPES.EDI_IFTMBC,
				edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
				edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
				edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
				edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
				edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_RECADV_DIFF_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
				edi.constants.DOCUMENT_TYPES.SLSRPT,
				edi.constants.DOCUMENT_TYPES.DP_CONTAINER,
				edi.constants.DOCUMENT_TYPES.DP_CONTAINER_NE,
				edi.constants.DOCUMENT_TYPES.ON_SERVICE_CONTAINER,
				edi.constants.DOCUMENT_TYPES.DP_CONTAINER_DR,
				edi.constants.DOCUMENT_TYPES.EDI_SLSRPT,
				edi.constants.DOCUMENT_TYPES.EDI_SLSFCT,
				edi.constants.DOCUMENT_TYPES.EDI_CONTRL,
				edi.constants.DOCUMENT_TYPES.EDI_INVRPT,
				edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT,
				edi.constants.DOCUMENT_TYPES.DSF_PRICAT,
				edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
				edi.constants.DOCUMENT_TYPES.DSF_CLIENT,
				edi.constants.DOCUMENT_TYPES.LEGACY_APERAK
			],
			INCLUDED_STATES: [
				edi.constants.STATE.DRAFT,
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SENDER_APPROVAL,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SENT,
				edi.constants.STATE.INVOICE_SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.SIGNED,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.CREATED,
				edi.constants.STATE.APPROVED_BY_DRIVER,
				edi.constants.STATE.SHIPPED,
				edi.constants.STATE.ACCEPTED_BY_RECEIVER,
				edi.constants.STATE.ACCEPTED_BY_DRIVER,
				edi.constants.STATE.CONFIRMED,
				edi.constants.STATE.ACTIVE,
				edi.constants.STATE.REJECTED,
				edi.constants.STATE.DELETED,
				edi.constants.STATE.WAIT_SENDER_ACCEPT,
				edi.constants.STATE.SEND_CORRECTION,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.CORRECTION,
				edi.constants.STATE.UTOCH,
				edi.constants.STATE.AMEND,
				edi.constants.STATE.CANCELED,
				edi.constants.STATE.ON_ANNUL,
				edi.constants.STATE.ANNUL_SIGN,
				edi.constants.STATE.RENOUNCEMENT,
				edi.constants.STATE.REPEALED
			],
			FILTER_REMOVED_STATES: [
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.CONFIRMED,
				edi.constants.STATE.SIGNED
			],
			QUICK_ANSWER: [edi.constants.DOCUMENT_TYPES.LEGACY_ORDER, edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV],
			QUICK_ANSWER_DOCFLOW_TYPES: {
				LEGACY_DES_ADV: [edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV],
				LEGACY_ORDER: [edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP]
			},
			CUSTOM_FIELDS_INCLUDED_TYPES: [
				edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
				edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
				edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
				edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
				edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
				edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
				edi.constants.DOCUMENT_TYPES.DSF_PRICAT,
				edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT,
				edi.constants.DOCUMENT_TYPES.DSF_CLIENT
			]
		},
		APERAK_DOCUMENT_TYPES: [
			edi.constants.APERAK_DOCUMENT_TYPES.INVOICE,
			edi.constants.APERAK_DOCUMENT_TYPES.DESADV,
			edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES,
			edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
			edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
			edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT,
			edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
			edi.constants.DOCUMENT_TYPES.EDI_IFTMBF,
			edi.constants.DOCUMENT_TYPES.EDI_IFTMBC,
			edi.constants.DOCUMENT_TYPES.EDI_IFTMBC_APPROVAL,
			edi.constants.DOCUMENT_TYPES.EDI_IFTMBF_APPROVAL,
			edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
			edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
			edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
			edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL,
			edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
			edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
			edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
			edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
			edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
			edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
			edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
			edi.constants.DOCUMENT_TYPES.DSF_PRICAT
		]
	}
});

edi.constants.SIGNATURE_SIDE = {
	INCOMING: {
		SUPPLIER: [edi.constants.DOCUMENT_TYPES.LEGACY_ORDER, edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV],
		CONSUMER: [
			edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
			edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
			edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
			edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES,
			edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
			edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
			edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
			edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
			edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL,
			edi.constants.DOCUMENT_TYPES.DSF_EDI_KC2,
			edi.constants.DOCUMENT_TYPES.DSF_EDI_KC3,
			edi.constants.DOCUMENT_TYPES.DSF_EDI_KC11,
			edi.constants.DOCUMENT_TYPES.DSF_EDI_KC14,
			edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
			edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
			edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
			edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
			edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
			edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
			edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
			edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
			edi.constants.DOCUMENT_TYPES.DSF_PRICAT
		]
	},
	OUTGOING: {
		CONSUMER: [
			edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
			edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
			edi.constants.DOCUMENT_TYPES.EDI_IFTMBF_APPROVAL
		],
		SUPPLIER: [
			edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
			edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
			edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES,
			edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
			edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
			edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
			edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
			edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
			edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL,
			edi.constants.DOCUMENT_TYPES.DSF_EDI_KC2,
			edi.constants.DOCUMENT_TYPES.DSF_EDI_KC3,
			edi.constants.DOCUMENT_TYPES.DSF_EDI_KC11,
			edi.constants.DOCUMENT_TYPES.DSF_EDI_KC14,
			edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
			edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
			edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
			edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
			edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
			edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
			edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
			edi.constants.DOCUMENT_TYPES.EDI_IFTMBC_APPROVAL,
			edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
			edi.constants.DOCUMENT_TYPES.DSF_PRICAT
		]
	},
	LOOP: {
		SUPPLIER: [edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN],
		CONSUMER: []
	},
	UNDEFINED: {
		SUPPLIER: [],
		CONSUMER: []
	}
};

edi.constants.WEB_UPLOAD_DOCTYPES = edi.constants.WEB_UPLOAD_DOCTYPES.concat([
	edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_RETANN,
	edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
	edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
	edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
	edi.constants.DOCUMENT_TYPES.DSF_RECADV_DIFF_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
	edi.constants.DOCUMENT_TYPES.EDI_SLSFCT,
	edi.constants.DOCUMENT_TYPES.LOGSET,
	edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
	edi.constants.DOCUMENT_TYPES.EDI_INVRPT,
	edi.constants.DOCUMENT_TYPES.DELFOR,
	edi.constants.DOCUMENT_TYPES.PACKING_LIST
]);

edi.constants.APERAK_CODE_FILTRES = [edi.constants.APERAK_CODES.CODE_29, edi.constants.APERAK_CODES.CODE_13];

edi.constants.APERAK_DOC_CODE_FILTRES = [
	//edi.constants.APERAK_CODES.CODE_34,
	edi.constants.APERAK_CODES.CODE_29,
	edi.constants.APERAK_CODES.CODE_27,
	edi.constants.APERAK_CODES.CODE_23,
	edi.constants.APERAK_CODES.CODE_13,
	edi.constants.APERAK_CODES.CODE_10,
	edi.constants.APERAK_CODES.CODE_6
];

edi.constants.taxRates = {
	taxNoVat: 'без НДС',
	taxVatByTaxAgent: 'НДС исчисляется налоговым агентом'
};

edi.constants.TAX_RATES = {
	NAMES: {
		0: '0',
		5: '5%',
		7: '7%',
		9: '9%',
		'9,09': '9,09%',
		10: '10%',
		12: '12%',
		'16,67': '16,67%',
		18: '18%',
		20: '20%',
		25: '25%',
		'5/105': '5/105',
		'7/107': '7/107',
		'10/110': '10/110',
		'18/118': '18/118',
		'20/120': '20/120'
	}
};

edi.constants.TAX_RATES.NAMES[edi.constants.taxRates.taxNoVat] = edi.constants.taxRates.taxNoVat;
edi.constants.TAX_RATES.NAMES[edi.constants.taxRates.taxVatByTaxAgent] = edi.constants.taxRates.taxVatByTaxAgent;

edi.constants.TAX_RATES.ALL = {
	zeroRate: {
		id: '0',
		name: edi.constants.TAX_RATES.NAMES['0']
	},
	percent5: {
		id: '5',
		name: edi.constants.TAX_RATES.NAMES['5']
	},
	percent7: {
		id: '7',
		name: edi.constants.TAX_RATES.NAMES['7']
	},
	percent9: {
		id: '9',
		name: edi.constants.TAX_RATES.NAMES['9']
	},
	percent9dot09: {
		id: '9,09',
		name: edi.constants.TAX_RATES.NAMES['9,09']
	},
	percent10: {
		id: '10',
		name: edi.constants.TAX_RATES.NAMES['10']
	},
	percent12: {
		id: '12',
		name: edi.constants.TAX_RATES.NAMES['12']
	},
	percent16dot67: {
		id: '16,67',
		name: edi.constants.TAX_RATES.NAMES['16,67']
	},
	percent18: {
		id: '18',
		name: edi.constants.TAX_RATES.NAMES['18']
	},
	percent20: {
		id: '20',
		name: edi.constants.TAX_RATES.NAMES['20']
	},
	percent25: {
		id: '25',
		name: edi.constants.TAX_RATES.NAMES['25']
	},
	part5of105: {
		id: '5/105',
		name: edi.constants.TAX_RATES.NAMES['5/105'],
		type: 'string',
		mathValue: function () {
			return (5 / 105) * 100;
		}
	},
	part7of107: {
		id: '7/107',
		name: edi.constants.TAX_RATES.NAMES['7/107'],
		type: 'string',
		mathValue: function () {
			return (7 / 107) * 100;
		}
	},
	part10of110: {
		id: '10/110',
		name: edi.constants.TAX_RATES.NAMES['10/110'],
		type: 'string',
		mathValue: function () {
			return (10 / 110) * 100;
		}
	},
	part18of118: {
		id: '18/118',
		name: edi.constants.TAX_RATES.NAMES['18/118'],
		type: 'string',
		mathValue: function () {
			return (18 / 118) * 100;
		}
	},
	part20of120: {
		id: '20/120',
		name: edi.constants.TAX_RATES.NAMES['20/120'],
		type: 'string',
		mathValue: function () {
			return (20 / 120) * 100;
		}
	},
	noVat: {
		id: edi.constants.taxRates.taxNoVat,
		name: edi.constants.TAX_RATES.NAMES[edi.constants.taxRates.taxNoVat],
		type: 'string',
		calculateVat: function (summ) {
			return summ;
		}
	},
	taxVatByTaxAgent: {
		id: edi.constants.taxRates.taxVatByTaxAgent,
		name: edi.constants.TAX_RATES.NAMES[edi.constants.taxRates.taxVatByTaxAgent],
		type: 'string'
	}
};

edi.constants.TAX_RATES.BY_COUNTRY = {
	//country ISO3
	RUS: [
		//0, 5%, 7%, 10%, 18%, 20%, 10/110, 18/118, 20/120, без НДС
		edi.constants.TAX_RATES.ALL.zeroRate,
		edi.constants.TAX_RATES.ALL.percent5,
		edi.constants.TAX_RATES.ALL.percent7,
		edi.constants.TAX_RATES.ALL.percent9dot09,
		edi.constants.TAX_RATES.ALL.percent10,
		edi.constants.TAX_RATES.ALL.percent16dot67,
		edi.constants.TAX_RATES.ALL.percent18,
		edi.constants.TAX_RATES.ALL.percent20,
		edi.constants.TAX_RATES.ALL.part5of105,
		edi.constants.TAX_RATES.ALL.part7of107,
		edi.constants.TAX_RATES.ALL.part10of110,
		edi.constants.TAX_RATES.ALL.part18of118,
		edi.constants.TAX_RATES.ALL.part20of120,
		edi.constants.TAX_RATES.ALL.noVat
	],
	EST: [
		//9% , 20%
		edi.constants.TAX_RATES.ALL.percent9,
		edi.constants.TAX_RATES.ALL.percent20
	],
	KAZ: [
		//0 , 12%
		edi.constants.TAX_RATES.ALL.zeroRate,
		edi.constants.TAX_RATES.ALL.percent12
	],
	UKR: [
		//0 , 7% , 20%
		edi.constants.TAX_RATES.ALL.zeroRate,
		edi.constants.TAX_RATES.ALL.percent7,
		edi.constants.TAX_RATES.ALL.percent20
	],
	BLR: [
		//0%, 10%, 20%, 25%, Без НДС
		edi.constants.TAX_RATES.ALL.zeroRate,
		edi.constants.TAX_RATES.ALL.percent10,
		edi.constants.TAX_RATES.ALL.percent20,
		edi.constants.TAX_RATES.ALL.percent25,
		edi.constants.TAX_RATES.ALL.noVat
	]
};

edi.constants.REJECT_DSFV3 = [
	edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
	edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
	edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL,
	edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
	edi.constants.DOCUMENT_TYPES.DSF_PRICAT,
	edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_KC2,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_KC3,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_KC11,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_KC14,
	edi.constants.DOCUMENT_TYPES.DSF_CLIENT
];

edi.constants.REJECT_PUSH_BP_NAMES = ['DSF'];

edi.constants.ACTUAL_FREE_DOCUMENT_TYPES = [
	edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
	edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
	edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL,
	edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
	edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_RECADV_DIFF_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP02,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP12,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP14,
	edi.constants.DOCUMENT_TYPES.DSF_PRICAT,
	edi.constants.DOCUMENT_TYPES.DSF_CLIENT
];

/*
 * Список типов, которые используются при создании ДСФ в document.create.dsf.js
 * Создан на основе ACTUAL_FREE_DOCUMENT_TYPES, из которого убран DSF_CLIENT
 * */
edi.constants.CREATE_ACTUAL_FREE_DOCUMENT_TYPES = [
	edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
	edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
	edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL,
	edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
	edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_RECADV_DIFF_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP02,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP12,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP14,
	edi.constants.DOCUMENT_TYPES.DSF_PRICAT
];

edi.constants.NOT_ACTUAL_FREE_DOCUMENT_TYPES = Ext.Array.difference(
	edi.constants.FREE_DOCUMENT_TYPES,
	edi.constants.ACTUAL_FREE_DOCUMENT_TYPES
);

edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS = {};
edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.LEGACY_ORDER] = [
	edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
	edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
	edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES
];
edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT] = [
	edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV
];
edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.EDI_IFTMBF] = [
	edi.constants.DOCUMENT_TYPES.EDI_IFTMBC
];

edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV] = [
	edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE
];

edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.EDI_INVRPT] = [
	edi.constants.DOCUMENT_TYPES.EDI_INVRPT
];

edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.EDI_SLSRPT] = [
	edi.constants.DOCUMENT_TYPES.EDI_SLSRPT
];

edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.EDI_SLSFCT] = [
	edi.constants.DOCUMENT_TYPES.EDI_SLSFCT
];

edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV] = [
	edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
	edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES
];

edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES] = [
	edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE
];

edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP] = [
	edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
	edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES,
	edi.constants.DOCUMENT_TYPES.LEGACY_APERAK
];

edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM] = [
	'PRICAT_EANCOM_V2_CANCELLATION',
	'PRICAT_EANCOM_V2_CORRECTION'
];

edi.constants.TRANSFORMATIONS_KEY_UPD = [];

edi.constants.DOCUMENT_SUBTYPES_FROM_TRANSFORMATIONS = {};

edi.constants.TRANSFORMATIONS_DOC_FLOW_TYPE_KEY = {};

Object.assign(edi.constants.ICONS, {
	LOOP: 'xe863@Material Icons',
	ANNUL: 'xe14b@Material Icons',
	ERROR: 'xe8b2@Material Icons',
	WARNING: 'xe88e@Material Icons'
});

edi.constants.HAS_PARENT_DOC_TYPES = [
	edi.constants.DOCUMENT_TYPES.EDI_DSF_DP_PRANNUL,
	edi.constants.DOCUMENT_TYPES.EDI_DSF_DP_PRANNUL_UTOCH,
	edi.constants.DOCUMENT_TYPES.EDI_DSF_DP_UVUTOCH,
	edi.constants.DOCUMENT_TYPES.EDI_DSF_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.DP_CONTAINER_IZVPOL,
	edi.constants.DOCUMENT_TYPES.DP_CONTAINER_UVUTOCH,
	edi.constants.DOCUMENT_TYPES.DP_CONTAINER_PRANNUL,
	edi.constants.DOCUMENT_TYPES.ON_AVZ_PRANNUL_UTOCH
];

edi.constants.PARENT_DOC_TYPES_BY_CHILDREN = {};
edi.constants.PARENT_DOC_TYPES_BY_CHILDREN[edi.constants.DOCUMENT_TYPES.DP_CONTAINER] = [
	edi.constants.DOCUMENT_TYPES.DP_CONTAINER_IZVPOL,
	edi.constants.DOCUMENT_TYPES.DP_CONTAINER_UVUTOCH,
	edi.constants.DOCUMENT_TYPES.DP_CONTAINER_PRANNUL
];

edi.constants.PARENT_DOC_TYPES_BY_CHILDREN[edi.constants.DOCUMENT_TYPES.ON_AVZ] = [
	edi.constants.DOCUMENT_TYPES.ON_AVZ_PRANNUL_UTOCH
];

// Real doc types for sign 2-part documents from documents grid
edi.constants.PARTS_DOC_TYPES = {};
edi.constants.CREATE_PART2_BY_ACTION = [];

edi.constants.BEFORE_SIGN_PARENT_DOC = [];

edi.constants.COMMON_PRICAT_BP_NAMES_FOR_FILTER = [
	edi.constants.DOCUMENT_TYPES.PRICAT_APPROVAL,
	edi.constants.DOCUMENT_TYPES.PRICAT_SIGN_APPROVAL,
	edi.constants.DOCUMENT_TYPES.PRICAT_SIMPLE,
	edi.constants.DOCUMENT_TYPES.PRICAT_WITH_SIGN
];

edi.constants.DOCS_ALLOW_ONLY_FULL_EXPORT = [
	edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
	edi.constants.DOCUMENT_TYPES.DP_CONTAINER,
	edi.constants.DOCUMENT_TYPES.DP_CONTAINER_DR,
	edi.constants.DOCUMENT_TYPES.DP_CONTAINER_NE
];

edi.constants.DOCS_ALLOW_ORIGINAL_EXPORT = [
	edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
	edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
	edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
	edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT,
	edi.constants.DOCUMENT_TYPES.LEGACY_RETANN
];

edi.constants.DOCS_ALLOW_TRANSIT_EXPORT = [
	edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
	edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
	edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
	edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT,
	edi.constants.DOCUMENT_TYPES.LEGACY_RETANN
];

edi.constants.DSF_CHECK_PERMISSION_FULL_EXPORT = [
	edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
	edi.constants.DOCUMENT_TYPES.FROV_SIG_SO_ACT2_FROM_X5
];

edi.constants.DOCS_ALLOW_ONLY_FULL_EXPORT_SHOW_BUTTON = [
	edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
	edi.constants.DOCUMENT_TYPES.DSF_REESTR_ROUTE,
	edi.constants.DOCUMENT_TYPES.CONTAINER_COMMON_PRICAT
];

edi.constants.EMAIL_NOTIFICATION_DOC_TYPES = [
	edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
	edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
	edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_RETANN,
	edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
	edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT,
	edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
	edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
	edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
	edi.constants.DOCUMENT_TYPES.DSF_RECADV_DIFF_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
	edi.constants.DOCUMENT_TYPES.DSF_PRICAT,
	edi.constants.DOCUMENT_TYPES.DSF_REESTR_ROUTE,
	edi.constants.DOCUMENT_TYPES.X5_AGREEMENT_CONTAINER,
	edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
	edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN_APERAK,
	edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
	edi.constants.DOCUMENT_TYPES.FROV_SIG_SO_ACT2_FROM_X5,
	edi.constants.DOCUMENT_TYPES.OVZ_FROM_X5
];

edi.constants.ORGANIZATION_NOTIFICATION_DOC_TYPES = [
	edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
	edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
	edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
	edi.constants.DOCUMENT_TYPES.DSF_REESTR_ROUTE,
	edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
	edi.constants.DOCUMENT_TYPES.DSF_PRICAT,
	edi.constants.DOCUMENT_TYPES.EDI_DSF_DP_PRANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
	edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
	edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
	edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN,
	edi.constants.DOCUMENT_TYPES.DP_CONTAINER_PRANNUL,
	edi.constants.DOCUMENT_TYPES.EDI_IFTMBC_APPROVAL,
	edi.constants.DOCUMENT_TYPES.FROV_SIG_SO_ACT2_FROM_X5,
	edi.constants.DOCUMENT_TYPES.ON_SERVICE_ATTACHMENT,
	edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
	edi.constants.DOCUMENT_TYPES.SUPPLIER_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.CONTAINER_COMMON_PRICAT,
	edi.constants.DOCUMENT_TYPES.X5_AGREEMENT_CONTAINER
];

/**
 * Функция осуществляет фильтрацию статусов недоступных при определённом состоянии конфигурации
 * формы редактирования почтовых уведомлений.
 * Сейчас некоторый статусы недоступны для некоторых направлений доставки, что явно указано в
 * коде функции.
 *
 * Не следует расширять логику функции если необходимо переопределить фильтрацию,
 * для конкретного типа документа, вместо этого, найдите в параметре
 * edi.constants.EMAIL_NOTIFICATION_DOCS_STATUSES узел содержащий необходимый тип документа
 * и добавьте в это узел параметр с ключём filter, в качестве значения должна быть функция,
 * принимающая два праметра, первый из них - список статусов, второй - дополнительные опции,
 * которые могут быть расширены в вызывающем коде. Функция должна вернуть массив статусов.
 *
 * @param {String[]} statuses
 * @param {Object} options
 * @return {String[]} filtered statuses
 */
edi.constants.EMAIL_NOTIFICATION_DOCS_STATUSES_DEFAULT_FILTER = (statuses, { direction, doctype }) => {
	switch (direction) {
		case edi.constants.DIRECTIONS.OUTGOING:
			return statuses.filter((status) => {
				return !~[
					edi.constants.STATE.RECEIVED_LEGACY_APERAK,
					edi.constants.STATE.SEND_TO_CRPT_SUCCESS,
					edi.constants.STATE.SEND_TO_CRPT_ERROR,
					edi.constants.STATE.ERROR_FS_ALL
				].indexOf(status);
			});
		case edi.constants.DIRECTIONS.INCOMING:
			return statuses.filter((status) => {
				return status !== edi.constants.STATE.SEND_TO_CRPT;
			});
		default:
			return statuses;
	}
};

edi.constants.DOCUMENT_CONDITIONAL_ACTIONS_DOCS_STATUSES = [
	{
		DOC_TYPES: [edi.constants.DOCUMENT_TYPES.LEGACY_ORDER],
		STATUSES: []
	}
];

edi.constants.DOCUMENT_TYPES_FOR_APERAK_STATUS = [
	edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
	edi.constants.DOCUMENT_TYPES.LEGACY_RETANN,
	edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
	edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
	edi.constants.DOCUMENT_TYPES.CONTAINER_COMMON_PRICAT
];
edi.constants.APERAK_CODE_FOR_STATUS = [6, 10, 12, 13, 23, 27, 29, 34];

edi.constants.DSF_FOR_MULTY_SIGN = [
	edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL,
	edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
	edi.constants.DOCUMENT_TYPES.DSF_PRICAT,
	edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
	edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
	edi.constants.DOCUMENT_TYPES.DSF_CLIENT
];

edi.constants.MODULES.DOCUMENTS.GENERATION_DOC_NUM = edi.constants.MODULES.DOCUMENTS.GENERATION_DOC_NUM || [];
edi.constants.MODULES.DOCUMENTS.GENERATION_DOC_NUM.push(edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN);

edi.constants.EXLUDE_XLS_PRINT_FORMATS_DOC_TYPES = [
	edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
	edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
	edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL,
	edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
	edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP02,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP12,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP14,
	edi.constants.DOCUMENT_TYPES.DSF_PRICAT
];

edi.constants.EXCLUDE_XLS_PRINT_FORMATS = [edi.constants.REPORT_FORMATS.PDF];

edi.constants.DOC_APERAK_WITHOUT_CHILD_CHECK = [
	edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
	edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
	edi.constants.DOCUMENT_TYPES.LEGACY_RETANN,
	edi.constants.DOCUMENT_TYPES.COMMON_PRICAT
];

edi.constants.FIRST_HALF_BUTTONS_ACTIONS = [
	edi.constants.DOCUMENT_ACTIONS.SIGN,
	edi.constants.DOCUMENT_ACTIONS.SEND,
	edi.constants.DOCUMENT_ACTIONS.SEND_APPROVE,
	edi.constants.DOCUMENT_ACTIONS.APPROVE,
	edi.constants.DOCUMENT_ACTIONS.COMPLETE,
	edi.constants.DOCUMENT_ACTIONS.READ_AND_COMPLETE,
	edi.constants.DOCUMENT_ACTIONS.READ,
	edi.constants.DOCUMENT_ACTIONS.QUICK_ANSWER_CONFIRM,
	edi.constants.DOCUMENT_ACTIONS.QUICK_ANSWER_REJECT,
	edi.constants.DOCUMENT_ACTIONS.ROUTE_CONFIRM,
	edi.constants.DOCUMENT_ACTIONS.ROUTE_REJECT,
	edi.constants.DOCUMENT_ACTIONS.CLARIFY,
	edi.constants.DOCUMENT_ACTIONS.EDIT,
	edi.constants.DOCUMENT_ACTIONS.PRINT,
	edi.constants.DOCUMENT_ACTIONS.REJECT
];

edi.constants.DEFAULTS_FIRST_HALF_ACTIONS_QUANTITY = 3;
edi.constants.DISABLE_DIFFERENT_DOCUMENTS_SELECTION_WARNING = false;
/**
 * При добавлении в эту константу не забывать вносить логику отправки с сам документ
 */
edi.constants.DOCS_WITH_BLOCK = edi.constants.DOCS_WITH_BLOCK.concat([
	edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
	edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
	edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
	edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
	edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED
]);

/**
 * Добавляет отображение ИНН, КПП и GLN в филды организаций в фильтрах документов
 */
edi.constants.USE_EXTENDED_ORG_DATA_VIEW_IN_FILTER_COMBO = true;

export const DOCS_WITH_BUSINESS_STATE = [
	edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
	edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
	edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL,
	edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
	edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP02,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP12,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP14,
	edi.constants.DOCUMENT_TYPES.DSF_PRICAT,
	edi.constants.DOCUMENT_TYPES.DSF_RECADV_DIFF_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_KC2,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_KC3,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_KC11,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_KC14,
	edi.constants.DOCUMENT_TYPES.DSF_CLIENT,
	edi.constants.DOCUMENT_TYPES.ON_AVZ,
	edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM
];

edi.constants.DOCS_WITH_ANNUL_LOG = [].concat(edi.constants.FREE_DOCUMENT_TYPES);

edi.constants.DOCUMENT_GROUP_REPORT_PRINT_CONFIG = [];
