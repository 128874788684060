/**
 * Requests configuration for core
 * @author Anatoly Deryshev
 */
Ext.namespace('edi.rest');
Object.assign(edi.rest.services, {
	FILE_STORAGE: {
		POST: edi.rest.prfx.c + 'file_storage',
		GET: edi.rest.prfx.c + 'file_storage/{fileStorageId}',
		OPEN: {
			GET: edi.rest.prfx.c + 'file_storage/{fileStorageId}/open'
		},
		PUT: edi.rest.prfx.c + 'file_storage/{fileStorageId}',
		DELETE: edi.rest.prfx.c + 'file_storage/{fileStorageId}',
		FILTER: {
			GET: edi.rest.prfx.c + 'file_storage'
		},
		TYPES: {
			GET: edi.rest.prfx.c + 'file_storage/doctypes/list'
		},
		CONTENT_TYPES: {
			GET: edi.rest.prfx.c + 'file_storage/content_types'
		},
		RESULTS: {
			GET: edi.rest.prfx.c + 'file_storage',
			PUT: edi.rest.prfx.c + 'file_storage/reprocess/{fileStorageResultId}'
		}
	},
	I18N: {
		SYSTEM: {
			TRANSLATION: {
				GROUP: {
					GET: edi.constants.DEFAULT.REST_PREFIX + 'public/translation/group/{groupName}/{lang}/{packageName}'
				}
			}
		}
	},
	VERSION: {
		GET: edi.rest.prfx.c + 'user/system/version'
	},
	USER_AGENT_LOG: {
		POST: edi.rest.prfx.c + 'user/information'
	},
	ORGANIZATION: {
		GET: edi.constants.DEFAULT.REST_PREFIX + 'common/organization/{org_id}?{type}',
		ALL: {
			POST: edi.constants.DEFAULT.REST_PREFIX + 'common/organizations'
		},
		NO_PARTNER: {
			GET: edi.rest.prfx.c + 'organization/no_partners'
		},
		NOT_INCLUDED_PARTNERS: {
			POST: edi.rest.prfx.c + 'organization/notincludedpartners'
		}
	},
	FILE: {
		POST: edi.rest.prfx.c + 'files/upload',
		GET: edi.rest.prfx.c + 'files/download/{id}'
	},
	SIGNER: {
		SIGNERS: {
			GET: edi.rest.prfx.c + 'signer/signers'
		}
	},
	REFRESH_TIMER: {
		GET: edi.rest.prfx.c + 'configuration/v2/refreshTimer'
	},
	COUNT_NOTICES: {
		GET: edi.rest.prfx.c + 'documents/unsignedNotices/count'
	},
	USER: {
		CERTIFICATE: {
			CERTIFICATES: {
				ACTIVE: {
					GET: edi.rest.prfx.c + 'user/certificate/certificates/active/{type}'
				}
			}
		},
		RECOVERY: {
			POST: edi.constants.DEFAULT.REST_PREFIX + 'public/user/password_recovery/{login}'
		},
		LOGIN: {
			POST: edi.rest.prfx.c + 'user/login'
		},
		CHECK_AUTH_TOKEN: {
			POST: edi.rest.prfx.c + 'user/auth'
		},
		EMAIL_CONFIRMATION: {
			GET: edi.rest.prfx.c + 'user/mail_confirm_doc'
		},
		SELF: {
			GET: edi.rest.prfx.c + 'users/self',
			PUT: edi.rest.prfx.c + 'users/self',
			CRD: {
				GET: edi.rest.prfx.c + 'user/crd',
				PUT: edi.rest.prfx.c + 'user/crd',
				AUTH: {
					PUT: edi.rest.prfx.c + 'user/crd/authentication'
				}
			},
			CHECK_MD: {
				GET: edi.rest.prfx.c + 'user/checkMD'
			},
			EXTRA_INFO: {
				GET: edi.rest.prfx.c + 'users/self/extra_info',
				PUT: edi.rest.prfx.c + 'users/self/extra_info'
			},
			EXTRA_INFO_ATTRIBUTES: {
				PUT: edi.rest.prfx.c + 'users/self/extra_info/attributes'
			},
			CERTIFICATE: {
				LIST: {
					GET: edi.rest.prfx.c + 'user/certificate'
				},
				ACTIVE: {
					GET: edi.rest.prfx.c + 'user/certificate/active/{type}'
				},
				ACTIVE_MODE: {
					GET: edi.rest.prfx.c + 'user/certificate/ui/active/mode'
				},
				POST: edi.rest.prfx.c + 'user/certificate',
				PUT: edi.rest.prfx.c + 'documents/{documentId}'
			},
			RESEND_CONFIRMATION_EMAIL: {
				PUT: edi.rest.prfx.c + 'users/self/resend_confirmation_email'
			}
		},
		POSITIONS: {
			GET: edi.rest.prfx.c + 'staff_list/worker_positions'
		},
		ORGANIZATION: {
			GET: edi.rest.prfx.c + 'user/organization',
			PUT: edi.rest.prfx.c + 'user/organization/{orgId}',
			LIST: {
				GET: edi.rest.prfx.c + 'user/organization/list'
			},
			GROUPS: {
				GET: edi.rest.prfx.c + 'org/groups'
			},
			ATTRIBUTES: {
				PUT: edi.rest.prfx.c + 'organization/add/attributes'
			},
			POSITIONS: {
				GET: edi.rest.prfx.c + 'staff_list/permanent_positions'
			},
			CHECK: {
				GET: edi.rest.prfx.c + 'user/check/organization/{orgId}'
			}
		},
		LOGOUT: {
			POST: edi.rest.prfx.c + 'user/logout'
		},
		PARTNERS: {
			GET: edi.rest.prfx.c + 'organization/partners',
			GROUPED: {
				GET: edi.rest.prfx.c + 'organization/grouped_partners'
			}
		},
		PERMISSIONS: {
			GET: edi.rest.prfx.c + 'permissions/groups/allowed'
		},
		BANK_ACCOUNTS: {
			GET: edi.rest.prfx.c + 'organization/{orgId}/bank_acc'
		}
	},
	COUNTRIES: {
		GET: 'json/countries.multi.json'
	},
	REGIONS: {
		GET: 'json/region_codes.json'
	},
	DOCUMENTS_EXPORT: {
		LIST: {
			POST: edi.rest.prfx.c + 'documents/export/list/{id}'
		}
	}
});
