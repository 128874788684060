/**
 * @author Vsevolod Liapin
 * Methods for data renderers
 */

Object.assign(edi.renderers.utils, {
	isFakePoa: function (docHeader) {
		return (
			docHeader?.type === edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY &&
			docHeader?.businessState === edi.constants.STATE.NOT_DEFINED
		);
	}
});

Object.assign(edi.renderers, {
	fileResult: function (value) {
		return edi.i18n.getMessage('file.result.' + value);
	},
	fileStorageProcessed: function (value, metaData, record) {
		const isClient = window.buildVersion.isAdmin !== true;
		const errorMessage = record.get('errorMessage');
		if (!isClient && errorMessage) {
			metaData.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(errorMessage) + '"';
		}
		let key = isClient && value === 'DRAFT' ? 'file.processed.user.' : 'file.processed.';
		return edi.i18n.getMessage(key + value);
	},
	reportStatus: function (value) {
		return [
			edi.constants.PRINT_STATUS.READY,
			edi.constants.PRINT_STATUS.ORDERED,
			edi.constants.PRINT_STATUS.COMPLETED,
			edi.constants.REPORT_STATE.GENERATING_ERROR
		].some((it) => it === value)
			? edi.i18n.getMessage('report.status.' + value)
			: edi.i18n.getMessage('report.status.none');
	},
	zeroAsEmpty: function (value) {
		if (Ext.isNumeric(value)) {
			value = edi.utils.roundTo(value, edi.core.getPrecisionNumber());
		}
		value = value ? String(value) : '';
		return value;
	},
	yesNo: function (value) {
		return value ? edi.i18n.getMessage('answer.yes') : edi.i18n.getMessage('answer.no');
	},
	existNo: function (value) {
		return value ? edi.i18n.getMessage('answer.exist') : edi.i18n.getMessage('answer.no');
	},
	templateType: function (value) {
		if (!value) {
			return '';
		}
		return edi.i18n.convertMessageToTemplateTypes(value);
	},
	pdfTemplateType: function (value) {
		return value ? edi.i18n.getMessage('documents.doctype.pdf.template.' + value) : '';
	},
	doctype: function (value, meta, record) {
		const docData = record?.getData ? record.getData() : record || {};
		const docType = docData.type;
		let versionId = '';
		let bpName = '';

		switch (docType) {
			case edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD:
			case edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD: {
				const bpAttr = edi.utils.getAttributeByName(docData.attributes, 'bpName');
				const updBPs = Object.values(edi.constants.DOCUMENT_BP_NAMES?.EDI_FNS_UPD ?? {});
				const ukdBPs = Object.values(edi.constants.DOCUMENT_BP_NAMES?.EDI_FNS_UKD ?? {});

				if (updBPs.some((it) => it === bpAttr) || ukdBPs.some((it) => it === bpAttr)) {
					bpName = '.' + bpAttr;
				}
				break;
			}
			case edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT: {
				let subDocType = edi.utils.getAttributeByName(docData.attributes, 'subDocType');
				if (subDocType) {
					return Ext.String.format(
						'<span class="doctype-{0}">{1}</span>',
						value,
						subDocType + ' ' + edi.i18n.getMessage('document.formalized.abbr')
					);
				}
				break;
			}
			case edi.constants.DOCUMENT_TYPES.DSF_CLIENT: {
				let subDocType = edi.utils.getAttributeByName(docData.attributes, 'subDocType');
				if (subDocType) {
					const subDocTypeTranslateKey = `documents.subdoctype.${subDocType}`;
					const subDocTypeTranslate = edi.i18n.getMessage(subDocTypeTranslateKey);
					const translate =
						(subDocTypeTranslateKey === subDocTypeTranslate ? subDocType : subDocTypeTranslate) +
						' ' +
						edi.i18n.getMessage('document.dsf_client.abbr');
					return Ext.String.format('<span class="doctype-{0}">{1}</span>', value, translate);
				}
				break;
			}
			case edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM: {
				versionId = !docData.versionId || docData.versionId === '1' ? '' : ' ' + docData.versionId;
				const docFlowAttr = edi.utils.getAttributeByName(docData.attributes, 'doc_flow_type');
				if (['PRICAT_EANCOM_V2_CANCELLATION', 'PRICAT_EANCOM_V2_CORRECTION'].includes(docFlowAttr)) {
					value = docFlowAttr;
					versionId = '';
				}
				break;
			}
			default:
				break;
		}

		value =
			Ext.String.format(
				'<span class="doctype-{0}">{1}</span>',
				value,
				edi.i18n.getMessage('documents.doctype.' + value + bpName)
			) + versionId;
		return value;
	},
	organization: function (value, metaData) {
		if (value) {
			var orgName = value.name + (value.inn ? ' ' + value.inn : '');
			metaData ? (metaData.tdAttr = 'data-qtip="' + edi.utils.safeString(orgName) + '"') : null;
			return orgName;
		}
		return '';
	},
	documentPartner: function (value, column, record) {
		var fromOrg = record.get('fromOrg'),
			toOrg = record.get('toOrg'),
			self = edi.core.getUserData();
		return self.org.id == fromOrg.id ? toOrg.name : fromOrg.name;
	},
	documentPartnerWithINN: function (value, metaData, record) {
		var fromOrg = record.get('fromOrg');
		var toOrg = record.get('toOrg');
		var self = edi.core.getUserData();
		if (self.org.id == fromOrg.id) {
			value = toOrg.name + ' ' + toOrg.inn;
		} else {
			value = fromOrg.name + ' ' + fromOrg.inn;
		}
		metaData.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(value) + '"';
		return value;
	},
	partnerWithFiliationsTooltip: function (value, meta, record) {
		var tooltip = '';
		if (record) {
			var headOrg = record.get('headOrg');
			if (headOrg && headOrg.id) {
				tooltip = edi.i18n.getMessage('org.is.filiation.of.another.org', {
					headOrg: record.get('headOrg').name
				});
			} else if (record.get('hasFilials')) {
				tooltip = edi.i18n.getMessage('org.is.head.org');
			}

			if (tooltip) {
				meta.tdAttr = 'data-qtip="' + tooltip + '"';
			}
		}
		return value;
	},
	certificateStatus: function (value) {
		return Ext.String.format(
			'<span class="status-{0}">{1}</span>',
			value,
			edi.i18n.getMessage('certificate.status.' + value)
		);
	},
	state: function (value) {
		return Ext.String.format('<span class="status-{0}">{1}</span>', value, edi.i18n.getTr(['STATE', value]));
	},
	perpetual: function (value, meta, record) {
		if (record && record.get('perpetual')) {
			value = edi.i18n.getMessage('column.perpetual');
		} else if (value) {
			value = edi.utils.formatDate(parseFloat(value), edi.constants.DATE_FORMAT.FNS);
		}
		return value || '';
	},
	dateTimeFromMs: function (value) {
		if (value) {
			value = edi.utils.formatDate(parseFloat(value), edi.constants.DATE_FORMAT.DATE_TIME_FNS);
		}
		return value || '';
	},
	directionIcon: function (value) {
		let html = '';
		if (edi.constants.DIRECTIONS.OUTGOING === value || edi.constants.DIRECTIONS.OUTBOX === value) {
			html = Ext.String.format(
				'<span class="dir-{0}" title="{1}">{1}</span>',
				'outgoing',
				edi.i18n.getMessage('documents.direction.outgoing')
			);
		} else if (edi.constants.DIRECTIONS.INCOMING === value || edi.constants.DIRECTIONS.INBOX === value) {
			html = Ext.String.format(
				'<span class="dir-{0}" title="{1}">{1}</span>',
				'incoming',
				edi.i18n.getMessage('documents.direction.incoming')
			);
		} else if (edi.constants.DIRECTIONS.INCOMING_FACTOR === value) {
			html = Ext.String.format(
				'<span class="dir-{0}" title="{1}">{1}</span>',
				'incoming',
				edi.i18n.getMessage('documents.direction.incoming')
			);
		} else if (edi.constants.DIRECTIONS.LOOP === value) {
			html = Ext.String.format(
				'<span class="dir-{0}" title="{1}">{1}</span>',
				'toself',
				edi.i18n.getMessage('documents.direction.toself')
			);
		} else {
			html = Ext.String.format(
				'<span class="dir-{0}" title="{1}">{1}</span>',
				'notdefined',
				edi.i18n.getMessage('documents.direction.notdefined')
			);
		}
		return html;
	},
	direction: function (value, column, record) {
		const fromOrg = record.get('fromOrg');
		const toOrg = record.get('toOrg');
		const factor = record.get('factor');
		return edi.renderers.directionIcon(edi.utils.getDocumentDirection(toOrg, fromOrg, factor));
	},
	certificateSubjectString: function (value, metaData, record) {
		let cert = edi.utils.certificateObject(value);
		var res = edi.renderers.certificateSubject(cert, undefined, record);
		if (res && metaData) {
			metaData.tdAttr = `data-qtip="${edi.renderers.certificateSubjectQtip(cert)}"`;
			metaData.tdAttr += ` data-qtipFormatted="true"`;
		}
		return res;
	},
	certificateIssuerString: function (value, metaData, record) {
		let cert = edi.utils.certificateObject(value);
		var res = edi.renderers.certificateIssuer(cert, undefined, record);
		if (res && metaData) {
			metaData.tdAttr = `data-qtip="${edi.renderers.certificateIssuerQtip(cert)}"`;
			metaData.tdAttr += ` data-qtipFormatted="true"`;
		}
		return res;
	},
	user: function (value) {
		var res = '';
		if (value) {
			var first = value.firstName;
			var patron = value.patronymicName;
			res = value.lastName + (first ? ' ' + first : '') + (patron ? ' ' + patron : '');
		}
		return res;
	},
	valueAsToolTip: function (value, metaData) {
		if (value) {
			metaData.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(String(value)) + '"';
		}
		return value;
	},
	certificateDocTypes: function (value, metaData) {
		var docTypes = value || [];
		value = [];
		for (var i = 0; i < docTypes.length; i++) {
			value.push(edi.i18n.getMessage('documents.doctype.' + docTypes[i]));
		}
		value = value.join(', ');
		if (value) {
			metaData.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(String(value)) + '"';
		}
		return value;
	},
	dateFromMs: function (value) {
		if (value) {
			value = edi.utils.formatDate(parseFloat(value), edi.constants.DATE_FORMAT.FNS);
		}
		return value || '';
	},
	fnsDateFromClient: function (value) {
		if (value) {
			value = edi.utils.formatDate(value, edi.constants.DATE_FORMAT.FNS, edi.constants.DATE_FORMAT.CLIENT);
		}
		return value || '';
	},
	docTime: function (value) {
		if (Ext.isNumeric(value)) {
			var tmp = new Date(parseFloat(value));
			if (Ext.isDate(tmp)) {
				tmp = Ext.Date.toUTC(tmp);
				tmp = tmp.getTime();
				value = edi.renderers.dateFromMs(tmp);
			}
		}
		return value;
	},
	complexErrorObject: function (data, metaData) {
		var translated = edi.i18n.getMessage(data),
			errData;
		if (translated === data) {
			errData = Ext.decode(data, true);
		}
		if (!errData) {
			errData = {
				typeError: data,
				additionalData: []
			};
		}
		errData = edi.utils.formatComplexServerError(errData, undefined, true);
		if (metaData) {
			metaData.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(errData) + '"';
		}
		return errData;
	},
	safeString: function (value) {
		//т.к. есть оверрайд для всех ячеек таблицы  тултипов, то принудительная очистка не требуется
		return value; //edi.utils.safeString(value);
	},
	encodedString: function (value) {
		//т.к. есть оверрайд для всех ячеек таблицы  тултипов, то принудительная очистка не требуется
		return value; //edi.utils.safeString(value, true, true);
	},
	currencyWithSeparators: function (value) {
		if (Ext.isNumeric(value)) {
			value = String(value);
			var tmp2 = value.split('-');
			var tmp = String(tmp2.length > 1 ? tmp2[1] : value).split('.');
			var val = tmp[0];
			if (val.length > edi.constants.CURRENCY_STACK_LENGTH) {
				tmp[0] = '';
				var fullLength = Math.floor(val.length / edi.constants.CURRENCY_STACK_LENGTH);
				var remainder = val.length % edi.constants.CURRENCY_STACK_LENGTH;
				if (!remainder) {
					fullLength--;
				}
				for (var i = 1; i <= fullLength; i++) {
					tmp[0] =
						edi.constants.CURRENCY_STACK_SEPARATOR +
						val.substr(
							val.length - i * edi.constants.CURRENCY_STACK_LENGTH,
							edi.constants.CURRENCY_STACK_LENGTH
						) +
						tmp[0];
				}
				tmp[0] = val.substr(0, remainder ? remainder : edi.constants.CURRENCY_STACK_LENGTH) + tmp[0];
			}
			value = tmp.join('.');
			if (tmp2.length > 1) {
				value = '-' + value;
			}
		}
		return value;
	},
	displayCurrencyDecimals: function (value) {
		return edi.renderers.currencyWithSeparators(edi.utils.displayCurrencyDecimals(+(value || 0)));
	},
	arrayCount: function (value) {
		if (Array.isArray(value)) {
			return value.length;
		} else {
			return '';
		}
	},
	addressTypeConvert: function (value) {
		var oldTypes = {
			RU_REAL: 'REAL',
			RU_LEGAL: 'LEGAL'
		};

		if (oldTypes.hasOwnProperty(value)) {
			value = oldTypes[value];
		}
		return value;
	},
	getTextDiffBetweenTwoDate: function (dateFromValue, dateToValue) {
		const { diffYears, diffMonth, diffDays } = edi.utils.getDiffBetweenTwoDate(dateFromValue, dateToValue);
		let text = '';
		if (diffYears) {
			const yearText = edi.i18n.decline(diffYears, 'yearVariants');
			if (!yearText) {
				return false;
			}
			text += `${diffYears} ${yearText}`;
		}
		if (diffMonth) {
			const monthText = edi.i18n.decline(diffMonth, 'monthVariants');
			if (!monthText) {
				return false;
			}
			text += `${text.length > 0 ? ' ' : ''}${diffMonth} ${monthText}`;
		}
		if (diffDays) {
			const daysText = edi.i18n.decline(diffDays, 'dayVariants');
			if (!daysText) {
				return false;
			}
			text += `${text.length > 0 ? ' ' : ''}${diffDays} ${daysText}`;
		}
		return text;
	},
	getCustomDecimalTranslation: function (total, fraction) {
		let defaultTranslate = edi.i18n.getMessage('invalid.number.decimal.custom', { total, fraction });
		const totalTextWord = edi.i18n.decline(total, 'charactersRodPadezh');
		const fractionTextWord = edi.i18n.decline(fraction, 'charactersRodPadezh');
		defaultTranslate = edi.i18n.customTranslation(
			'custom.invalid.number.decimal.custom',
			{ total, fraction, totalTextWord, fractionTextWord },
			defaultTranslate
		);
		return defaultTranslate;
	},
	// Для отображения названий статусов, специфических для МЧД
	//Пришлось перенести в ядро, т.к. требуется для отображения данных сертификатов
	powerOfAttorneySpecificStatuses: ['EXPIRED', 'ON_REGISTRATION', 'REVOKED', 'REVOKED', 'REJECTED'],
	powerOfAttorneyFixStatusCode: function (codeText) {
		return edi.renderers.powerOfAttorneySpecificStatuses.includes(codeText)
			? 'POWER_OF_ATTORNEY_' + codeText
			: codeText;
	},
	poaStateRenderer: function (value, metaData, record) {
		const recordData = record?.getData();
		value =
			recordData && edi.renderers.utils.isFakePoa(recordData)
				? record.get('businessState')
				: edi.renderers.powerOfAttorneyFixStatusCode(value);
		return edi.renderers.status(value, metaData, record);
	}
});
