/**
 * @author Vsevolod Liapin
 * Column configurations for grids
 */

edi.columns.addColumns({
	file_storage: {
		id: {
			text: 'column.id',
			dataIndex: 'id',
			flex: 1,
			sortable: false
		},
		guid: {
			text: 'column.guid',
			dataIndex: 'guid',
			flex: 1,
			hidden: true,
			sortable: false
		},
		docName: {
			text: 'column.doc.name',
			dataIndex: 'docName',
			flex: 2,
			sortable: true
		},
		contentType: {
			text: 'column.content.type',
			dataIndex: 'contentType',
			flex: 1,
			sortable: true
		},
		contentEncoding: {
			text: 'column.content.encoding',
			dataIndex: 'contentEncoding',
			flex: 1,
			hidden: true,
			sortable: true
		},
		docType: {
			text: 'column.doc.type',
			dataIndex: 'docType',
			flex: 2,
			sortable: true
		},
		byOrg: {
			text: 'column.by.org',
			dataIndex: 'byOrg',
			renderer: 'organization',
			flex: 2,
			sortable: true
		},
		toOrg: {
			text: 'documents.form.partner',
			dataIndex: 'toOrg',
			renderer: 'organization',
			flex: 2,
			hidden: true,
			sortable: true
		},
		byUser: {
			text: 'column.by.user',
			dataIndex: 'byUser',
			renderer: 'user',
			flex: 2,
			hidden: true,
			sortable: true
		},
		creationDate: {
			text: 'column.creation.date',
			dataIndex: 'creationDate',
			renderer: 'dateTimeFromMs',
			flex: 1,
			hidden: true,
			sortable: true
		},
		modifyDate: {
			text: 'column.modify.date',
			dataIndex: 'modifyDate',
			renderer: 'dateTimeFromMs',
			flex: 1,
			sortable: true
		},
		processed: {
			text: 'column.processed',
			dataIndex: 'processed',
			renderer: 'fileStorageProcessed',
			flex: 1,
			sortable: true
		}
	},
	fs_rule: {
		id: {
			text: 'column.id',
			dataIndex: 'id',
			flex: 1,
			sortable: false
		},
		creationDate: {
			text: 'column.creation.date',
			dataIndex: 'creationDate',
			renderer: 'dateTimeFromMs',
			flex: 1,
			sortable: false
		},
		modifyDate: {
			text: 'column.modify.date',
			dataIndex: 'modifyDate',
			renderer: 'dateTimeFromMs',
			flex: 1
		},
		docType: {
			text: 'column.doc.type',
			dataIndex: 'docType',
			flex: 2,
			sortable: false
		},
		contentType: {
			text: 'column.content.type',
			dataIndex: 'contentType',
			flex: 1,
			sortable: false
		},
		markupRuleId: {
			text: 'markupRuleId',
			dataIndex: 'markupRuleId',
			flex: 1,
			sortable: false
		},
		active: {
			text: 'column.displayed',
			dataIndex: 'active',
			flex: 1,
			hideable: false,
			renderer: 'yesNo',
			sortable: false
		}
	},
	transformation_include: {
		id: {
			text: 'column.id',
			dataIndex: 'id',
			flex: 1,
			sortable: false
		},
		filename: {
			text: 'receipt.file.name',
			dataIndex: 'filename',
			flex: 1,
			sortable: false
		}
	},
	file_results: {
		id: {
			text: 'column.id',
			dataIndex: 'id',
			flex: 1,
			hidden: true,
			sortable: false
		},
		byModule: {
			text: 'column.by.module',
			dataIndex: 'byModule',
			flex: 1,
			sortable: true
		},
		result: {
			text: 'column.result',
			dataIndex: 'processed',
			flex: 1,
			renderer: 'fileResult',
			sortable: true
		},
		resultDoc: {
			text: 'column.result.doc',
			dataIndex: 'docName',
			flex: 2,
			hidden: true,
			sortable: false
		},
		original: {
			text: 'column.original.doc.name',
			dataIndex: 'attrs',
			flex: 1,
			sortable: true,
			renderer: function (val) {
				return val.docNameInTP || val.filename || val.original_name_s || '';
			}
		},
		errorMessage: {
			text: 'column.error.message',
			dataIndex: 'errorMessage',
			htmlEncode: true,
			renderer: 'complexErrorObject',
			flex: 2,
			sortable: true
		},
		creationDate: {
			text: 'column.creation.date',
			dataIndex: 'creationDate',
			renderer: 'dateTimeFromMs',
			flex: 1,
			hidden: true,
			sortable: true
		},
		modifyDate: {
			text: 'column.modify.date',
			dataIndex: 'modifyDate',
			renderer: 'dateTimeFromMs',
			flex: 1,
			sortable: true
		}
	},
	organization: {
		name: {
			text: 'column.org.name',
			dataIndex: 'name',
			flex: 2,
			hideable: false
		},
		inn: {
			text: 'column.org.inn',
			dataIndex: 'inn',
			flex: 1
		},
		kpp: {
			text: 'column.org.kpp',
			dataIndex: 'kpp',
			flex: 1
		},
		regNr: {
			text: 'column.org.regNr',
			dataIndex: 'regNr',
			flex: 1
		},
		iln: {
			text: 'column.org.iln',
			dataIndex: 'iln',
			flex: 1
		}
	},
	organization_catalog: {
		iln: {
			text: 'column.org.iln',
			dataIndex: 'iln',
			flex: 1,
			hideable: false
		},
		name: {
			text: 'column.org.name',
			dataIndex: 'name',
			flex: 2,
			minWidth: 350,
			hideable: false
		},
		streetAndNumber: {
			text: 'column.address',
			dataIndex: 'streetAndNumber',
			flex: 2,
			minWidth: 400,
			hideable: true,
			sortable: false
		}
	},
	agreement_base: {
		number: {
			text: 'column.agreement.number',
			dataIndex: 'number',
			flex: 1
		},
		owner: {
			text: 'column.agreement.owner',
			dataIndex: 'owner.name',
			flex: 1.5,
			sortable: false
		},
		supplier: {
			text: 'column.agreement.supplier',
			dataIndex: 'supplier.name',
			flex: 1.5,
			sortable: false
		},
		consumer: {
			text: 'column.agreement.consumer',
			dataIndex: 'consumer.name',
			flex: 1.5,
			sortable: false
		},
		factor: {
			text: 'column.agreement.factor',
			dataIndex: 'factor.name',
			flex: 1.5,
			sortable: false
		},
		validFrom: {
			text: 'column.agreement.valid.from',
			dataIndex: 'validFrom',
			flex: 1,
			renderer: 'dateFromMs'
		},
		validTo: {
			text: 'column.agreement.valid.to',
			dataIndex: 'validTo',
			flex: 1,
			renderer: 'perpetual'
		}
	},
	simple: {
		id: {
			text: 'column.id',
			dataIndex: 'id',
			flex: 1,
			hideable: false
		},
		name: {
			text: 'column.name',
			dataIndex: 'name',
			flex: 2,
			htmlEncode: true,
			hideable: false
		}
	},
	simple_editable: {
		id: {
			text: 'column.name',
			dataIndex: 'name',
			flex: 1,
			tdCls: 'editable',
			editor: {
				xtype: 'ui-textfield',
				allowBlank: false
			}
		}
	},
	document_logs: {
		id: {
			text: 'column.log.id',
			dataIndex: 'id',
			flex: 1,
			hidden: true
		},
		objectId: {
			text: 'documents.column.id',
			dataIndex: 'objectId',
			flex: 1,
			sortable: false,
			hidden: true
		},
		docNumber: {
			text: 'documents.column.number',
			dataIndex: 'docNumber',
			sortable: false,
			flex: 1
		},
		docType: {
			text: 'documents.column.type',
			dataIndex: 'docType',
			flex: 1,
			sortable: false,
			hidden: true
		},
		creationDate: {
			text: 'column.log.creation.date',
			dataIndex: 'creationDate',
			flex: 1,
			hideable: true,
			hidden: false,
			renderer: 'dateTimeFromMs'
		},
		org: {
			text: 'column.log.org',
			dataIndex: 'org',
			flex: 2,
			hideable: false,
			sortable: false,
			renderer: function (value, metaData) {
				const val = value?.name || '';
				metaData.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(val) + '"';
				return val;
			}
		},
		user: {
			text: 'column.log.user',
			dataIndex: 'user',
			flex: 2,
			hideable: false,
			sortable: false,
			renderer: function (value, metaData) {
				if (!value) return;
				var val =
					value.lastName + ' ' + value.firstName + (value.patronymicName ? ' ' + value.patronymicName : '');
				metaData.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(val) + '"';
				return val;
			}
		},
		userLogin: {
			text: 'login.form.login',
			dataIndex: 'userLogin',
			sortable: false,
			flex: 1
		},
		action: {
			text: 'column.log.action',
			dataIndex: 'action',
			sortable: false,
			flex: 3,
			hideable: false,
			renderer: function (value, metaData) {
				const val = edi.i18n.getMessage(value);
				metaData.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(val) + '"';
				return val;
			}
		},
		additionalInfo: {
			text: 'column.log.additionalInfo',
			dataIndex: 'additionalInfo',
			flex: 1,
			sortable: false,
			hidden: true
		}
	},
	certificate: {
		subject: {
			text: 'column.certificate.subject',
			dataIndex: 'subject',
			renderer: 'certificateSubject',
			sortable: false,
			flex: 3
		},
		issuer: {
			text: 'column.certificate.issuer',
			dataIndex: 'issuer',
			renderer: 'certificateIssuer',
			sortable: false,
			flex: 2
		},
		dateFromParsed: {
			text: 'column.certificate.dateFrom',
			dataIndex: 'dateFromParsed',
			renderer: 'dateFromMs',
			flex: 1
		},
		dateToParsed: {
			text: 'column.certificate.dateTo',
			dataIndex: 'dateToParsed',
			renderer: 'dateFromMs',
			flex: 1
		}
	},
	client_notifications: {
		id: {
			text: 'documents.column.id',
			sortable: true,
			flex: 1,
			hidden: true,
			dataIndex: 'id'
		},
		creationDate: {
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			flex: 1,
			hidden: true,
			renderer: 'dateTimeFromMs'
		},
		number: {
			text: 'documents.column.number',
			sortable: false,
			flex: 1,
			dataIndex: 'number'
		},
		doctime: {
			text: 'documents.column.date',
			dataIndex: 'doctime',
			flex: 1,
			sortable: false,
			renderer: 'docTime'
		},
		direction: {
			text: 'documents.column.direction',
			sortable: false,
			flex: 1,
			renderer: 'direction',
			htmlEncode: true
		},
		partner: {
			text: 'documents.column.document.partner',
			dataIndex: 'fromOrgName',
			sortable: false,
			flex: 2
		},
		type: {
			text: 'documents.column.type',
			dataIndex: 'type',
			flex: 1.5,
			sortable: false,
			htmlEncode: true,
			renderer: function (value, meta) {
				meta.tdAttr =
					'data-qtip="' + edi.utils.replaceQuotes(edi.i18n.getMessage('documents.doctype.' + value)) + '"';
				value = Ext.String.format(
					'<span class="doctype-{0}">{1}</span>',
					value,
					edi.i18n.getMessage('documents.doctype.' + value)
				);
				return value;
			}
		},
		numactionTextber: {
			text: 'notification.column.action',
			dataIndex: 'actionText',
			sortable: false,
			flex: 2
		}
	},
	templates_with_status: {
		templateId: {
			text: 'column.id',
			dataIndex: 'templateId',
			flex: 1
		},
		templateName: {
			text: 'column.name',
			dataIndex: 'templateName',
			flex: 2
		},
		reportStatus: {
			text: 'column.report.status',
			dataIndex: 'reportStatus',
			renderer: 'reportStatus',
			flex: 1
		},
		format: {
			text: 'column.report.format',
			dataIndex: 'format',
			sortable: false,
			flex: 0.75
		}
	},
	complex_request: {
		url: {
			text: 'debug.request.url',
			sortable: false,
			flex: 3,
			xtype: 'treecolumn',
			dataIndex: 'url',
			htmlEncode: true,
			renderer: function (value, metaData) {
				value = edi.utils.safeString(value, true, true);
				metaData.tdAttr = 'data-qtip="' + value + '"';
				return value;
			}
		},
		method: {
			text: 'debug.request.type',
			dataIndex: 'method',
			sortable: false,
			flex: 0.5
		}
	},
	complex_request_result: {
		uri: {
			text: 'debug.request.url',
			sortable: false,
			flex: 3,
			dataIndex: 'uri',
			renderer: function (value, metaData, record) {
				value = edi.utils.safeString(value, true, true);
				metaData.tdAttr = 'data-qtip="' + value + '"';
				var add = '',
					level = record.get('level');
				for (var i = 0; i < level; i++) {
					add += ' -> ';
				}
				if (add.length) {
					value = '[' + add + '] ' + value;
				}
				return value;
			}
		},
		method: {
			text: 'debug.request.type',
			dataIndex: 'method',
			sortable: false,
			flex: 0.5
		},
		time: {
			text: 'debug.request.time',
			dataIndex: 'time',
			renderer: 'dateTimeFromMs',
			flex: 1
		},
		error: {
			text: 'debug.request.with.error',
			dataIndex: 'error',
			sortable: true,
			hidden: true,
			renderer: 'yesNo',
			flex: 0.5
		}
	},
	complex_request_map: {
		field: {
			text: 'debug.request.map.field',
			dataIndex: 'field',
			flex: 1
		},
		path: {
			text: 'debug.request.map.field.path',
			dataIndex: 'path',
			flex: 3
		}
	},
	complex_request_include: {
		field: {
			text: 'debug.request.map.field',
			dataIndex: 'field',
			flex: 1
		},
		type: {
			text: 'debug.request.include.type',
			dataIndex: 'type',
			renderer: function (val) {
				return edi.i18n.getMessage('debug.request.include.type.' + val);
			},
			flex: 1
		},
		value: {
			text: 'debug.request.include.values',
			dataIndex: 'value',
			renderer: function (val) {
				var retVal = val;
				if (val.length) {
					retVal = val.join('<br/>');
				}
				return retVal;
			},
			flex: 2
		}
	},
	complex_request_include_values: {
		id: {
			text: 'debug.request.include.id',
			dataIndex: 'id',
			hidden: true,
			flex: 1
		},
		name: {
			text: 'debug.request.include.value',
			dataIndex: 'name',
			flex: 3,
			tdCls: 'editable',
			editor: {
				xtype: 'ui-textfield',
				allowBlank: true
			}
		}
	}
});

edi.columns.addColumns({
	certificate_sign: {
		parents: 'certificate',
		config: {
			availableDocTypesForSignature: {
				text: 'column.certificate.documents',
				dataIndex: 'availableDocTypesForSignature',
				renderer: 'certificateDocTypes',
				sortable: false,
				order: 3,
				flex: 2
			}
		}
	},
	organization_with_filiations: {
		parents: 'organization',
		config: {
			name: {
				renderer: 'partnerWithFiliationsTooltip'
			}
		}
	}
});
